import styled from "styled-components";

export const DashboardWrapper = styled.div`
    max-width: 340px;
    padding-top: 45px;
    margin: 0 auto;
`;

export const StyledHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
  .left {
    text-decoration: none;
    display: flex;
    color: var(--text1-color);
    align-items: center;
    gap: 5px;
    p {
      font-size: 12px;
      font-weight: 700;
    }
  }
  .right {
    background: var(--channel-back);
    border-radius: 19px;
    width: 135px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    a{
      text-decoration: none;
      cursor: pointer;
      color: var(--text1-color);
      text-align: center;
      font-size: 14px;
    }
  }
`;
export const StyledWeekDays = styled.div`
  display: flex;
  justify-content: center;
  gap: 20px;
  .item {
    border-radius: 6px;
    width: 34px;
    height: 47px;
    display: flex;
    padding-top: 5px;
    justify-content: flex-start;
    gap: 5px;
    flex-direction: column;
    align-items: center;
    font-family: "TT Travels Trl", sans-serif;
    font-weight: 300;
    font-size: 10px;
    background-color: #807faa;
    .check {
      width: 19px;
      height: 19px;
      background-color: #110055;
      border-radius: 50%;
      .checked {
        display: flex;
        justify-content: center;
        align-items: center;
        padding-top: 2px;
      }
    }
  }
  .active {
    background: #5351a7;
  }
  .disabled{
    background: gray;
  }
`;
export const StyledGrid = styled.div`
  margin-top: 30px;
  display: grid;
  grid-template-columns: repeat(2,minmax(140px ,160px));
  justify-content: center;
  gap: 30px;
  .item {
    cursor: pointer;
    position: relative;
    display: flex;
    justify-content: center;
    img{
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    .text {
      position: absolute;
      bottom: 10px;
      font-family: "TT Travels Trl", sans-serif;
      width: max-content;
      max-width: 110px;
      text-align: center;
      left: 50%;
      transform: translateX(-50%);
      font-weight: 700;
      font-size: 15px;
      color: white;
    }
    .reviewCount{
      position: absolute;
      top: -5px;
      right: -5px;
      z-index: 2;
      cursor: help;
      text-decoration: none;
      background-color: var(--text1-color);
      width: 20px;
      border-radius: 50%;
      color: red;
      font-size: 16px;
      line-height: 16px;
      font-weight: 700;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 20px;
    }
  }
`;
