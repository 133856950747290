import {NutritionWrapper,} from "./styled";
import {Link, useNavigate} from "react-router-dom";
import PageTitle from "../../components/pageTitle";
import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {selectAllUsers, selectAllUsersPage} from "../../store/features/auth/selectors";
import {getAllUsers, setUsersPage, clearUsersRows} from "../../store/features/auth/slice";

const Nutrition = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const allUsers = useSelector(selectAllUsers());
    const page = useSelector(selectAllUsersPage());

    useEffect(() => {
        dispatch(getAllUsers())
    }, [dispatch, page])

    useEffect(() => {
        return () => {
            dispatch(clearUsersRows())
            dispatch(setUsersPage(1))
        }
    }, [dispatch]);

    return (
        <NutritionWrapper>
            <PageTitle
                title={"План питания"}
                backFunc={() => navigate(-1)}
            />
            <div style={{marginTop: "50px", paddingBottom: '40px'}}>
                {allUsers?.rows?.map((item, index) => {
                    return (
                        <Link to={`/nutrition/${item.id}`}
                              key={index}
                              className="item"
                        >
                            {item.first_name + ' ' + item.last_name}
                        </Link>
                    );
                })}
                {allUsers.count > allUsers.rows.length &&
                    <button className={'loadMoreBtn'}
                            onClick={() => dispatch(setUsersPage(page + 1))}>Ещё</button>}
            </div>
        </NutritionWrapper>
    );
};
export default Nutrition;
