import React, { useEffect, useState } from "react";
import PageTitle from "../../../components/pageTitle";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { deleteReview, editReview, getPendingReviews } from "../../../store/features/reviews/slice";
import { selectPendingReviews } from "../../../store/features/reviews/selectors";
import { StyledNewReviewItem, StyledReview } from "../styled";
import star from '../../../images/icons/star.svg'
import EditReviewModal from "../../../components/modals/editReviewModal";

const NewReview = () => {
  const navigate = useNavigate();
  const pendings = useSelector(selectPendingReviews());
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [selected, setSelected] = useState(null)

  useEffect(() => {
    dispatch(getPendingReviews());
  }, [dispatch]);

  const handleOk = () => {
    if (isEdit) {
      dispatch(editReview(selected))
    } else {
      dispatch(deleteReview(selected))
    }
    setShowModal(false)
  }

  return (
    <StyledReview>
      <PageTitle
        title="Новые отзывы"
        backFunc={() => navigate(-1)}
      />
      <br />
      {pendings?.rows?.map(item => {
        return (
          <StyledNewReviewItem key={item.id}>
            <div className="title">
              <p>
                {item?.user?.first_name + ' ' + item?.user?.last_name}
              </p>
              <p className="stars">{item.stars} <img src={star} alt="star" /></p>
            </div>
            <p className="text">{item?.description}</p>
            <div className="buttons">
              <button onClick={() => {
                setShowModal(true)
                setIsEdit(true)
                setSelected(item.id)
              }}>Принять</button>
              <button onClick={() => {
                setShowModal(true)
                setSelected(item.id)
                setIsEdit(false)
              }}>Удалить</button>
            </div>
          </StyledNewReviewItem>
        )
      })}
      <EditReviewModal handleOk={handleOk} isEdit={isEdit} open={showModal} handleModal={() => setShowModal(false)} />
    </StyledReview>
  );
};

export default NewReview;
