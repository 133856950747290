import styled from "styled-components";

export const StyledUsers = styled.div`
    max-width: 340px;
    padding: 45px 0;
    margin: 0 auto;
    .item{
        font-size: 15px;
        border: 2px solid var(--text1-color);
        border-radius: 8px;
        margin-bottom: 18px;
        font-weight: 500;
        color: var(--text1-color);
        display: flex;
        align-items: center;
        padding: 0 12px;
        height: 48px;
        cursor: pointer;
        display: flex;
        justify-content: space-between;
        .d-flex{
            display: flex;
            align-items: center;
            gap: 16px;
        }
        .check{
            width: 28px;
            height: 28px;
            border-radius: 50%;
            border: 1px solid var(--text1-color);
        }
        .ACTIVE_PENDING{
            cursor: pointer;
            background-color: yellow;
        }
        .PENDING{
            cursor: pointer;
            background-color: yellow;
        }
        .CANCELED{
            cursor: pointer;
            background-color: red;
        }
        .NEW{
            background-color: #606060;
        }
        .ACTIVE{
            background-color: #04f904;
        }
    }
    .loadMoreBtn {
        display: block;
        width: max-content;
        margin: 20px auto 0 auto;
        background-color: lightgreen;
        padding: 8px;
        border-radius: 5px;
        border: none;
        cursor: pointer;
    }
`
