import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { client, clientForm } from "../../../api";

const initialState = {
    loading: false,
    pending: [],
    custom: [],
    actives: [],
    customById: null,
    carts: [],
};

export const getPendingReviews = createAsyncThunk(
    "review/getPendingReviews",
    async (_, { rejectWithValue }) => {
        try {
            const data = {
                page: 1,
                limit: 50,
                status: "PENDING"
            }
            const response = await client.post(`/reviews/get-all`, data);

            return response.data.payload;
        } catch (err) {
            rejectWithValue(err);
        }
    }
);
export const getActiveReviews = createAsyncThunk(
    "review/getActiveReviews",
    async (_, { rejectWithValue }) => {
        try {
            const data = {
                page: 1,
                limit: 50,
                status: "ACTIVE"
            }
            const response = await client.post(`/reviews/get-all`, data);

            return response.data.payload;
        } catch (err) {
            rejectWithValue(err);
        }
    }
);

export const deleteReview = createAsyncThunk(
    "review/deleteReview",
    async (id, { rejectWithValue, dispatch }) => {
        try {
            const response = await client.delete(`/reviews/${id}`);
            if (response.data.payload) {
                dispatch(getPendingReviews())
            }
            return response.data.payload;
        } catch (err) {
            rejectWithValue(err);
        }
    }
);

export const editReview = createAsyncThunk(
    "review/editReview",
    async (id, { rejectWithValue, dispatch }) => {
        try {
            const response = await client.patch(`/reviews/${id}`, { status: "ACTIVE" });
            if (response.data.payload) {
                dispatch(getPendingReviews())
            }
            return response.data.payload;
        } catch (err) {
            rejectWithValue(err);
        }
    }
);

export const postCustom = createAsyncThunk(
    "review/postCustom",
    async (data, { rejectWithValue }) => {
        try {
            const response = await client.post(`/custom`, data);

            return response.data.payload;
        } catch (err) {
            rejectWithValue(err);
        }
    }
);

export const editCustom = createAsyncThunk(
    "review/editCustom",
    async (data, { rejectWithValue }) => {
        try {
            const response = await client.patch(`/custom/${data.id}`, data.body)

            return response.data.payload;
        } catch (err) {
            rejectWithValue(err);
        }
    }
);

export const getAllNews = createAsyncThunk(
    "review/getAllNews",
    async (model, { rejectWithValue }) => {
        try {
            const response = await client.post(`/custom/get-all`, { model });

            return response.data.payload;
        } catch (err) {
            rejectWithValue(err);
        }
    }
);
export const deleteCustom = createAsyncThunk(
    "review/deleteCustom",
    async (data, { rejectWithValue, dispatch }) => {
        try {
            const response = await client.post(`/custom/delete`, data);
            if (response.data.payload) {
                dispatch(getAllNews(data.model))
            }
            return response.data.payload;
        } catch (err) {
            rejectWithValue(err);
        }
    }
);

export const getOneCustom = createAsyncThunk(
    "review/getOneCustom",
    async (data, { rejectWithValue }) => {
        try {
            const response = await client.post(`/custom/get-one`, data);

            return response.data.payload;
        } catch (err) {
            rejectWithValue(err);
        }
    }
);
export const uploadFile = createAsyncThunk(
    "review/uploadFile",
    async (data, { rejectWithValue }) => {
        try {
            const response = await clientForm.post(`/file/custom_files`, data);
            const res = {
                id: response.data.payload.id,
                url: response.data.payload.url
            }
            return res;
        } catch (err) {
            rejectWithValue(err);
        }
    }
);
export const deleteUploadedFile = createAsyncThunk(
    "review/uploadFile",
    async (id, { rejectWithValue }) => {
        try {
            const response = await client.delete(`/file/custom_files/${id}`);

            return response.data.payload;
        } catch (err) {
            rejectWithValue(err);
        }
    }
);

export const getCarts = createAsyncThunk(
    "review/getCarts",
    async (_, { rejectWithValue }) => {
        try {
            const response = await client.post(`/custom/get-all`, { model: "Payment" });

            return response.data.payload;
        } catch (err) {
            rejectWithValue(err);
        }
    }
);
export const deleteCart = createAsyncThunk(
    "review/deleteCart",
    async (id, { rejectWithValue }) => {
        try {
            const response = await client.post(`/custom/delete`, { model: "Payment", id });

            return response.data.payload;
        } catch (err) {
            rejectWithValue(err);
        }
    }
);
export const addCart = createAsyncThunk(
    "review/addCart",
    async (data, { dispatch, rejectWithValue }) => {
        try {
            const response = await client.post(`/custom/payment`, data);
            if (response?.data?.payload) {
                dispatch(getCarts())
            }
            return response.data.payload;
        } catch (err) {
            rejectWithValue(err);
        }
    }
);
export const editCart = createAsyncThunk(
    "review/editCart",
    async ({ id, data }, { dispatch, rejectWithValue }) => {
        try {
            const response = await client.patch(`/custom/payment/${id}`, data);
            if (response.data.payload) {
                dispatch(getCarts())
            }
            return response.data.payload;
        } catch (err) {
            rejectWithValue(err);
        }
    }
);

const reviewSlice = createSlice({
    name: "review",
    initialState,
    reducers: {
        clearCustomData: (state) => {
            state.custom = []
        },
        clearbyIdCutom: (state) => {
            state.customById = null
        }
    },
    extraReducers(builder) {
        builder.addCase(getPendingReviews.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(getPendingReviews.fulfilled, (state, action) => {
            state.loading = false;
            state.pending = action.payload;
        });
        builder.addCase(getPendingReviews.rejected, (state) => {
            state.loading = false;
        });
        builder.addCase(getActiveReviews.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(getActiveReviews.fulfilled, (state, action) => {
            state.loading = false;
            state.actives = action.payload;
        });
        builder.addCase(getActiveReviews.rejected, (state) => {
            state.loading = false;
        });
        builder.addCase(getAllNews.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(getAllNews.fulfilled, (state, action) => {
            state.loading = false;
            state.custom = action.payload;
        });
        builder.addCase(getAllNews.rejected, (state) => {
            state.loading = false;
        });
        builder.addCase(getOneCustom.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(getOneCustom.fulfilled, (state, action) => {
            state.loading = false;
            state.customById = action.payload;
        });
        builder.addCase(getOneCustom.rejected, (state) => {
            state.loading = false;
        });
        builder.addCase(getCarts.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(getCarts.fulfilled, (state, action) => {
            state.loading = false;
            state.carts = action.payload;
        });
        builder.addCase(getCarts.rejected, (state) => {
            state.loading = false;
        });
        builder.addCase(uploadFile.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(uploadFile.fulfilled, (state, action) => {
            state.loading = false;
        });
        builder.addCase(uploadFile.rejected, (state) => {
            state.loading = false;
        });
    },
});


export const { clearCustomData, clearbyIdCutom } = reviewSlice.actions
export default reviewSlice.reducer;
