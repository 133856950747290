import {useState} from "react";
import {SelectWrapper} from "./styled";
import {IoIosArrowDown} from "react-icons/io";

const Select = ({
                    options,
                    values,
                    name,
                    value,
                    label,
                    onchange,
                    isExerciseUpdate,
                    setFieldValue,
                    loadMoreBtnFunc,
                    searchInputFunc,
                    searchInputValue
                }) => {
    const [active, setActive] = useState(false);

    return (
        <SelectWrapper
            $activeLabel={value?.length > 0 || active}
            $active={active}
            onClick={() => {
                setActive(!active);
            }}
        >
            <label htmlFor={name}>{label}</label>
            <IoIosArrowDown className="arrow"/>
            <div className="value">
                {values && values?.find((i) => i.exercise_id === value) ?
                    <p>{values?.find((i) => i.exercise_id === value)?.exercise}</p>
                    :
                    <p>{options?.find((i) => i.id === value)?.name}</p>
                }
                <div className="dropDown" onClick={(e) => {
                    e.stopPropagation()
                }}>
                    {searchInputFunc &&
                        <input value={searchInputValue} placeholder={'Поиск'} type="text" className={'searchInput'}
                               onChange={(e) => searchInputFunc(e)}/>
                    }
                    {options?.map((item, index) => {
                        return (
                            <div
                                key={index}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    onchange(item);
                                    setActive(false)
                                    setFieldValue(item.name, item.id);
                                }}
                            >
                                <input
                                    type="radio"
                                    checked={value === item.id}
                                    onChange={() => {
                                        setFieldValue(item.name, item.id);
                                    }}
                                />
                                {item.name}
                            </div>
                        );
                    })}
                    {loadMoreBtnFunc &&
                        <button className={'loadMoreBtn'} onClick={loadMoreBtnFunc}>Ещё</button>
                    }
                </div>
            </div>
        </SelectWrapper>
    );
};

export default Select;
