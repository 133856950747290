import React from "react";
import { StyledFullInfo } from "./styled";

const FullInfo = ({ data }) => {
  return (
    <StyledFullInfo>
      <p className="name">{data.name}</p>
      <div className="text">{data.description}</div>
      <iframe src={data.video_url} title={data.name}></iframe>
    </StyledFullInfo>
  );
};

export default FullInfo;
