import styled from "styled-components";

export const BanksModalContent = styled.div`
  .title{
    width: 100%;
    font-size: 15px;
    color: var(--text1-color);
    text-align: center;
  }
  button{
    display: block; 
    margin: 0 auto;
    padding: 10px 20px;
    color: var(--text1-color);
    background-color: transparent;
    border: 1px solid var(--text1-color);
    border-radius: 20px;
    cursor: pointer;
    
    &:disabled{
      color: gray;
      cursor: default;
      border-color:gray;
    }
  }
  
`;
