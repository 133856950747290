import { MdKeyboardArrowLeft } from "react-icons/md";
import { StyledPageTitle } from "./styled";

const PageTitle = ({ title, backFunc, editFunc, editText }) => {
  return (
    <StyledPageTitle>
      <p className="back" onClick={backFunc}>
        <MdKeyboardArrowLeft />
        назад
      </p>
      <p className="title">{title}</p>
      <p className="edit" onClick={editFunc}>
        {editText &&
          editText
        }
      </p>
    </StyledPageTitle>
  );
};

export default PageTitle;
