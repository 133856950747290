import styled, { css } from "styled-components";
import { StyledCalendarWrapper } from "../../../components/calendar/styled";

export const StyledFullInfo = styled.div`
    max-width: 340px;
    padding-top: 45px;
    margin: 0 auto;
    .username{
        font-size: 24px;
        font-weight: 700;
        color: var(--text1-color);
        text-align: center;
    }
    .group{
        margin-top: 20px;
        font-size: 17px;
        font-weight: 700;
        color: var(--text1-color);
        text-align: center;
    }
    .selectTitle{
        color: var(--text1-color);
        font-size: 14px;
        margin: 8px 0;
    }
    .select{
        border: 2px solid var(--text1-color);
        color: var(--text1-color);
        font-size: 15px;
        height: 48px;
        padding-left: 12px;
        margin-bottom: 16px;
        border-radius: 9px;
        display: flex;
        align-items: center;
    }
    button{
        width: 154px;
        height: 48px;
        cursor: pointer;
        margin:26px auto 0 auto;
        gap: 8px;
        border-radius: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: transparent;
        color: var(--text1-color);
        border: 2px solid var(--text1-color);
        font-size: 16px;
        font-weight: 700;
    }
`;

export const StyledCalendarDrop = styled.div`
    width: 100%;
    text-align: center;
    margin-top: 20px;
    border: 2px solid var(--text1-color);
    border-radius: 8px;
    height: ${(props) => props.open ? 'auto' : '48px'};
    padding: 12px;
    color: var(--text1-color);
   
   
  .react-calendar__tile {
    position: relative;
  }
   background: ${(props) => props.open ? 'var(--calendar-back)' : 'transparent'};
    .title{
            display: flex;
            justify-content: space-between;
            align-items: center;
            position: relative;
            font-size: 15px;
            font-weight: 500;
            text-align: start;
            .arrow{
                ${(props) => props.open ? css`
                transform:rotate(180deg);
                ` : null}
            }
    }
    .drop{
        margin-top: 10px;
        height: ${(props) => props.open ? 'auto' : '0'};
        display: ${(props) => props.open ? 'flex' : 'none'};
        overflow-x: auto;
        width: 100%;
        justify-content: flex-start;
        gap: 5px;
        scroll-snap-type: x mandatory;
        .img{
            background-color:#d9d9d9;
            width: 97px;
            min-width: 97px;
            height: 97px;
            img{
                width: 100%;
                height: 100%;
                object-fit:cover;
            }
        }
        &::-webkit-scrollbar {
            display: none;
        }
    }
    ${StyledCalendarWrapper}{
        border: none;
        margin: 0;
        padding: 0;
        width: 100%;
        background-color: transparent;
        .calendar{
            margin-top: -15px;
            .react-calendar__navigation__label{
                margin: 10px auto;
                cursor: default;
            }
        }
    }
    /* ${StyledCalendarWrapper}{
        margin: 0;
        padding:4px 10px;
        .calendar{
            .react-calendar__navigation{
                .react-calendar__navigation__label{
                    margin:0 auto
                }
            }
            .react-calendar__month-view__days{
                gap: 7px;
                grid-template-columns: repeat(7,1fr);
                .item{
                    width: 34px;
                    height: 34px;
                }
            }   
        }
    } */
`
