import { StyledProfileWrapper } from "./styled";
import PageTitle from "../../components/pageTitle";
import { useNavigate, useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import {
  selectUserById,
} from "../../store/features/auth/selectors";
import { useEffect, useState } from "react";
import { getOneUser } from "../../store/features/auth/slice";
import CalendarComp from "../../components/calendar";
import CalendarSelect from "../../components/calendar/dropDown";
import GroupsModal from "../../components/modals/groupsModal";
import { Link } from "react-router-dom";

const Profile = () => {
  const data = useSelector(selectUserById());
  const dispatch = useDispatch();
  const params = useParams();
  const navigate = useNavigate();
  const [upcoming, setUpcoming] = useState([]);
  const [past, setPast] = useState([]);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if (params) {
      dispatch(getOneUser(params.id));
    }
  }, [dispatch, params]);

  useEffect(() => {
    const currentDate = new Date();
    const currentDay = currentDate.getDate();

    if (data?.UserExercise) {
      const keys = Object.keys(data.UserExercise.exercises);
      setPast(
        keys.map(i => {
          if (+i < currentDay) {
            return {
              isNext: false,
              date: +i
            }
          }
          return null
        }).filter(j => j)
      );
      setUpcoming(
        keys.map(i => {
          if (+i >= currentDay) {
            return {
              isNext: false,
              date: +i
            }
          }
          return null
        }).filter(j => j)
      );
    }

    if (data?.NextMonthUserExercise) {
      const keys = Object.keys(data.NextMonthUserExercise.exercises);

      setUpcoming((prev) => [
        ...prev,
        ...keys.map(i => {
          if (+i < currentDay) {
            return {
              isNext: true,
              date: +i
            }
          }
          return null
        }).filter(j => j)
      ]);
    }
  }, [data]);


  const getBackground = (day) => {
    if (day <= new Date().getDate()) {
      if (data?.UserExercise?.completed_days?.includes(day)) {
        return "#538B4E";
      }
      return "#A7565177";
    } else if (day >= new Date().getDate() && !data?.UserExercise?.completed_days?.includes(day)) {
      return "#ccc";
    } else {
      return "transparent";
    }
  };

  const isCurrentMonth = (date) => date.getMonth() === new Date().getMonth();
  const isNextMonth = (date) => date.getMonth() === new Date().getMonth() + 1;

  const getDayStyle = (day, data, isCurrent) => {
    const exerciseData = isCurrent ? data?.UserExercise : data?.NextMonthUserExercise;
    if (exerciseData?.all_days.includes(day)) {
      return getBackground(day);
    }
    return null;
  };

  const tileContent = ({ date, view }) => {
    if (view === "month") {
      const day = date.getDate();

      if (isNextMonth(date) && data?.NextMonthUserExercise?.all_days?.includes(day)) {
        return (
          <p className="tileItem" style={{ background: "#ccc" }}>
            {day}
          </p>
        );
      }

      let backgroundStyle;

      if (isCurrentMonth(date)) {
        backgroundStyle = getDayStyle(day, data, true);
      }

      if (backgroundStyle) {
        return (
          <p className="tileItem" style={{ background: backgroundStyle }}>
            {day}
          </p>
        );
      }
    }

    return null;
  };
  const isNext = localStorage?.getItem('nextMonth')
  const value = isNext ? new Date(new Date().setMonth(new Date().getMonth() + 1)) : new Date();

  return (
    <StyledProfileWrapper>
      <PageTitle
        title="Тренировки"
        backFunc={() => navigate(-1)}
        editFunc={() => setShowModal(!showModal)}
        editText='Редактировать'
      />
      <p className="username">{data?.first_name + " " + data?.last_name}</p>
      <br />
      <br />
      <CalendarComp
        onchange={(e) => {
          const day = e.getDate()
          if (e.getMonth() > new Date().getMonth()) {
            localStorage.setItem('nextMonth', true)
          } else {
            localStorage.removeItem('nextMonth')
          }
          navigate(`/profile/${params.id}/${day}/update?next=${e.getMonth() === new Date().getMonth() ? 'false' : 'true'}`)
        }}
        onChangeMonth={e => {
          if (e.getMonth() > new Date().getMonth()) {
            localStorage.setItem('nextMonth', true)
          } else {
            localStorage.removeItem('nextMonth')
          }
        }}
        value={value}
        tileContent={tileContent}
      />
      <CalendarSelect options={upcoming} label="Предстоящие тренировки" />
      <CalendarSelect options={past} label="Прошедшие тренировки" />
      <br />
      <Link to={`/progress/${params.id}`}>Прогресс</Link>
      <GroupsModal
        open={showModal}
        handleModal={() => setShowModal(false)}
      />
    </StyledProfileWrapper>
  );
};

export default Profile;
