import React from "react";
import { ModalContent } from "./styled";
import Modal from "react-modal";
import { IoIosClose } from "react-icons/io";
import { useDispatch } from "react-redux";
import { getAllUsers, updateUserSubscription } from "../../../store/features/auth/slice";

const SubscribeModal = ({ open, handleModal, selected }) => {


  Modal.setAppElement("#root");
  const app = document.querySelector(".App");
  const isLightTheme = app?.classList?.value.includes("light");
  const dispatch = useDispatch()
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      width: "340px",
      paddingTop: '50px',
      background: "var(--background)",
      borderRadius: "21px",
      border: "2px solid var(--text1-color)",
    },
    overlay: {
      background: "transparent",
    },
  };

  const handleOk = () => {
    const data = { subscription_status: "ACTIVE" }
    dispatch(updateUserSubscription({ id: selected?.id, data })).then(res => {
      if (res.payload) {
        handleModal(false)
        dispatch(getAllUsers())
      }
    })
  }
  const handleCancel = () => {
    const data = { subscription_status: "CANCELED" }
    dispatch(updateUserSubscription({ id: selected?.id, data })).then(res => {
      if (res.payload) {
        handleModal(false)
        dispatch(getAllUsers())
      }
    })
  }

  const formatDateReverse = (date) => {
    const formattedDate = date.toLocaleDateString('en-US');
    const [month, day, year] = formattedDate.split('/');

    // Добавляем ноль перед числом дня, если оно меньше 10
    const paddedDay = day.padStart(2, '0');
    const paddedMonth = month.padStart(2, '0');

    // Собираем дату в нужном формате
    const formatted = `${paddedDay}.${paddedMonth}.${year}`;
    return formatted;
  }

  return (
    <Modal
      className={isLightTheme ? "light lightModal" : ""}
      isOpen={open}
      onRequestClose={() => handleModal(false)}
      style={customStyles}
    >
      {open && (
        <IoIosClose
          className="modalCloseIcon"
          onClick={() => handleModal(false)}
        />
      )}
      <ModalContent>
        <p className="title">
          {
            selected?.subscription_status === "PENDING" &&
            "Подтвердить подписку пользователя "
          }
          {selected?.first_name}{' '}
          {selected?.last_name}
        </p>
        <br />
        {
          selected?.subscription_status === "PENDING" ?
            <>
              <p className="title">В : {selected?.payment?.title}</p>
              <p className="title">На : {selected?.subscription_time} месяцев</p>
            </>
            :
            selected?.subscription_status === "ACTIVE" ?
              <p className="title">Активен до : {formatDateReverse(new Date(selected?.subscription_end))}</p> :
              selected?.subscription_status === "ACTIVE_PENDING" ?
                <>
                  <p className="title">Активен до : {formatDateReverse(new Date(selected?.subscription_end))}</p>
                  <p className="title">+</p>
                  <p className="title">В : {selected?.payment?.title}</p>
                  <p className="title">На : {selected?.subscription_time} месяцев</p>
                </>
                : ''
        }
        <div className="flex">
          {
            (selected?.subscription_status === "PENDING" || selected?.subscription_status === "ACTIVE_PENDING") &&
            <button onClick={handleOk}>Принять</button>
          }
          <button onClick={handleCancel}>Отменить</button>
        </div>
      </ModalContent>
    </Modal>
  );
};

export default SubscribeModal;
