import React, { useEffect, useState } from "react";
import { ModalContent } from "./styled";
import Modal from "react-modal";
import { IoIosClose } from "react-icons/io";

const translate = {
  breakfast: 'Завтрак',
  lunch: 'Обед',
  dinner: 'Ужин',
  snack: 'Перекус',
}

const NutritionModal = ({ open, handleModal, title, setGlobalData, globalData }) => {
  Modal.setAppElement("#root");
  const app = document.querySelector(".App");
  const isLightTheme = app?.classList?.value.includes("light");
  const [value, setValue] = useState('')

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      width: "340px",
      background: "var(--background)",
      borderRadius: "21px",
      border: "2px solid var(--text1-color)",
    },
    overlay: {
      background: "transparent",
    },
  };

  useEffect(() => {
    if (!open) {
      setValue('')
    } else {
      if (globalData[title]?.length > 0) {
        setValue(globalData[title])
      }
    }
  }, [open])

  const handleSave = () => {
    setGlobalData({
      ...globalData,
      [title]: value
    })
    handleModal()
  }

  return (
    <Modal
      className={isLightTheme ? "light lightModal" : ""}
      isOpen={open}
      onRequestClose={() => handleModal(false)}
      style={customStyles}
    >
      {open && (
        <IoIosClose
          className="modalCloseIcon"
          onClick={() => handleModal(false)}
        />
      )}
      <ModalContent>
        <div className="title">{translate[title]}</div>
        <textarea
          value={value}
          onChange={(e) => setValue(e.target.value)}
          rows="6"
        />
        <button onClick={handleSave}>Сохранить</button>
      </ModalContent>
    </Modal>
  );
};

export default NutritionModal;
