import React, { useEffect, useState } from "react";
import { BanksModalContent } from "./styled";
import Modal from "react-modal";
import { IoIosClose } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import { getGroups, updateUserGroup } from "../../../store/features/auth/slice";
import { useParams } from "react-router";
import { selectAllGroups, selectUserById } from "../../../store/features/auth/selectors";

const GroupsModal = ({ open, handleModal }) => {
  Modal.setAppElement("#root");
  const app = document.querySelector(".App");
  const isLightTheme = app?.classList?.value.includes("light");
  const params = useParams();
  const groups = useSelector(selectAllGroups())
  const userById = useSelector(selectUserById())
  const dispatch = useDispatch()
  const [selectedGroup, setSelectedGroup] = useState(null)
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      width: "340px",
      background: "var(--background)",
      borderRadius: "21px",
      border: "2px solid var(--text1-color)",
    },
    overlay: {
      background: "transparent",
    },
  };

  useEffect(() => {
    if (userById) {
      dispatch(getGroups(userById?.gender))
    }
  }, [dispatch, userById])

  const handleSave = () => {
    dispatch(updateUserGroup({ id: params.id, data: { group_id: selectedGroup.id } })).then(res => {
      if (res.payload) {
        handleModal()
      }
    })
  }

  return (
    <Modal
      className={isLightTheme ? "light lightModal" : ""}
      isOpen={open}
      onRequestClose={() => handleModal(false)}
      style={customStyles}
    >
      {open && (
        <IoIosClose
          className="modalCloseIcon"
          onClick={() => handleModal(false)}
        />
      )}
      <BanksModalContent>
        <p className="title">Редактировать группу</p>
        <div className="flex">
          {groups?.rows?.map(item => {
            return (
              <p key={item.id} className={selectedGroup?.id === item.id ? 'selected' : ''}
                onClick={(e) => setSelectedGroup(item)}>{item?.name}
              </p>
            )
          })}
        </div>
        <button onClick={handleSave} disabled={!selectedGroup}>Сохранить</button>
      </BanksModalContent>
    </Modal>
  );
};

export default GroupsModal;
