import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    loading: false,
    techniques: null,
    techniqueById: null
};

// export const getTechniques = createAsyncThunk(
//     "auth/getTechniques",
//     async (_, { rejectWithValue }) => {
//         try {
//             const response = await sendRequestWithToken({
//                 method: 'GET',
//                 url: '/exercise',
//             });

//             return response.payload;
//         } catch (err) {
//             rejectWithValue(err);
//         }
//     }
// );


const techniqueSlice = createSlice({
    name: "technique",
    initialState,
    reducers: {},
    extraReducers(builder) {
        // builder.addCase(getTechniques.pending, (state) => {
        //     state.loading = true;
        // });
        // builder.addCase(getTechniques.fulfilled, (state, action) => {
        //     state.loading = false;
        //     state.techniques = action.payload
        // });
        // builder.addCase(getTechniques.rejected, (state) => {
        //     state.loading = false;
        // });

    },
});

export default techniqueSlice.reducer;
