import React, { useState } from "react";
import { StyledTechnique, StyledTechniqueItem } from "./styled";
import PageTitle from "../../components/pageTitle";
import SearchInput from "../../components/serachInput";
import { FaAngleRight } from "react-icons/fa";
import FullInfo from "./fullInfo";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
import { selectTechniques } from "../../store/features/technique/selectors";

const Technique = () => {
  const [fullInfo, setFullInfo] = useState(null);
  const navigate = useNavigate();
  const techniques = useSelector(selectTechniques());

  return (
    <StyledTechnique>
      <PageTitle
        title="Техника упражнений"
        backFunc={fullInfo ? () => setFullInfo(null) : () => navigate(-1)}
      />
      <br />
      {fullInfo ? (
        <FullInfo data={fullInfo} />
      ) : (
        <>
          <SearchInput />
          <br />
          <br />
          {techniques?.map((item) => {
            return (
              <StyledTechniqueItem
                onClick={() => {
                  setFullInfo(item);
                }}
                key={item.id}
              >
                {item.name}
                <FaAngleRight />
              </StyledTechniqueItem>
            );
          })}
        </>
      )}
    </StyledTechnique>
  );
};

export default Technique;
