import { DashboardWrapper, StyledGrid, StyledHeader } from "./styled";
import img2 from "../../images/dashboardContent/2.png";
import img4 from "../../images/dashboardContent/4.png";
import img3 from "../../images/dashboardContent/users.png";
import img10 from "../../images/dashboardContent/10.png";
import img5 from "../../images/dashboardContent/5.png";
import Ellipse19 from "../../images/dashboardContent/Ellipse19.png";
import { FaAngleRight } from "react-icons/fa";
import SubscriptionModal from "../../components/modals/subscriptionModal";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { selectPendingReviews } from "../../store/features/reviews/selectors";
import { getPendingReviews } from "../../store/features/reviews/slice";

const Dashboard = () => {
  const navigate = useNavigate()

  const blocks = [
    { img: img2, text: "Тренировки", url: "/trinings" },
    { img: img4, text: "Питание", url: "/nutrition" },
    { img: img3, text: "Пользователи", url: "/users" },
    { img: img10, text: "Отзывы", url: "/review" },
    { img: img5, text: "Новости", url: "/custom" },
  ];

  const [openModal, setOpenModal] = useState(false);
  const pendings = useSelector(selectPendingReviews());
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getPendingReviews());
  }, [dispatch]);

  return (
    <DashboardWrapper>
      <StyledHeader>
        <div className="left">
          <img src={Ellipse19} alt="" />
          <p>Админ</p>
          <FaAngleRight />
        </div>
        <div className="right">
          <Link to={'/addCart'} >Способы оплаты</Link>
        </div>
      </StyledHeader>

      <StyledGrid>
        {blocks.map((item, index) => {
          return (
            <div
              key={index}
              className="item"
              onClick={() => {
                if (item.url) {
                  navigate(item.url)
                } else {
                  setOpenModal(!openModal)
                }
              }
              }
            >
              <img key={index} src={item.img} alt="icon" />
              <p className="text">{item.text}</p>
              {item.url === '/review' && pendings?.count > 0 ?
                <p onClick={(e) => {
                  e.stopPropagation()
                  navigate('/newReviews')
                }}
                  className="reviewCount">{pendings?.count}</p>
                : ''}
            </div>
          );
        })}
      </StyledGrid>
      <br />
      <br />
      <SubscriptionModal open={openModal} handleModal={setOpenModal} />
    </DashboardWrapper>
  );
};
export default Dashboard;
