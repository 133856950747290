import { UpdateWrapper } from "./styled";
import PageTitle from "../../../components/pageTitle";
import { useLocation, useNavigate, useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import {
    selectExercisePage, selectUserById, selectUserExercises, selectUserExercisesCount, selectUserExercisesSearch
} from "../../../store/features/auth/selectors";
import { useEffect, useState } from "react";
import {
    clearExerciseRows, getAllExercisesForUser, getOneUser, setExercisePage, setExerciseSearch
} from "../../../store/features/auth/slice";
import Input from "../../../components/input";
import Select from "../../../components/select";
import {
    createUserExercise, createUserExerciseNextMonth, deleteUserExerciseNext,
} from "../../../store/features/exercises/slice";
import { FaAngleRight } from "react-icons/fa";
import CalendarModal from "../../../components/modals/calendarModal";
import DeleteExerciseModal from "../../../components/modals/deleteExerciseModal";

const useQuery = () => {
    return new URLSearchParams(useLocation().search);
};
const ProfileUpdate = () => {
    const user = useSelector(selectUserById());
    const exercises = useSelector(selectUserExercises());
    const page = useSelector(selectExercisePage());
    const count = useSelector(selectUserExercisesCount());
    const search = useSelector(selectUserExercisesSearch());
    const dispatch = useDispatch();
    const params = useParams();
    const navigate = useNavigate();
    const query = useQuery();
    const isNext = query.get('next') === 'true';

    const macket = {
        exercise: "",
        exercise_id: "",
        quantity: "",
        working_weight: null,
        approaches: "",
        time: "",
        have_working_weight: false
    };
    const [rowsCount, setRowsCount] = useState([macket]);
    const [showEdit, setShowEdit] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [disableBtn, setDisableBtn] = useState(true);
    const [comment, setComment] = useState('');
    const [name, setName] = useState('');

    const getExercises = (user, day, isNext) => {
        if (isNext) {
            setName(user?.NextMonthUserExercise?.exercises[day]?.name)
            setComment(user?.NextMonthUserExercise?.exercises[day]?.comment)
            return user?.NextMonthUserExercise?.exercises[day]?.exercises || [macket];
        } else {
            setName(user?.UserExercise?.exercises[day]?.name)
            setComment(user?.UserExercise?.exercises[day]?.comment)
            return user?.UserExercise?.exercises[day]?.exercises || [macket];
        }
    };

    const isDayCompleted = (user, day, isNext) => {
        if (isNext) {
            return user?.NextMonthUserExercise?.completed_days?.includes(+day);
        } else {
            return user?.UserExercise?.completed_days?.includes(+day);
        }
    };

    useEffect(() => {
        if (params && !user) {
            dispatch(getOneUser(params.id));
        }

        if (user) {
            const exercises = getExercises(user, params.day, isNext);
            setRowsCount(exercises);
            setShowEdit(!isDayCompleted(user, params.day, isNext));
        } else {
            setRowsCount([macket]);
        }
    }, [dispatch, params, user, isNext]);

    useEffect(() => {
        if (user) {
            dispatch(getAllExercisesForUser());
        }
    }, [dispatch, user, page, search]);

    useEffect(() => {
        return () => {
            dispatch(setExercisePage(1))
            dispatch(setExerciseSearch(''))
            dispatch(clearExerciseRows())
        }
    }, [dispatch]);

    useEffect(() => {
        const isAnyFieldEmpty = rowsCount.some(row => {
            const values = Object.entries(row);
            const isOtherFieldEmpty = values.some(item => item[0] !== "have_working_weight" && item[0] !== "approaches" && item[0] !== "quantity" && item[0] !== "time" && item[0] !== 'working_weight' && item[1] === '');
            const isQuantityAndTimeEmpty = (!row.quantity || row.quantity === '') && row.time === ''
            return isOtherFieldEmpty || isQuantityAndTimeEmpty;
        });

        setDisableBtn(isAnyFieldEmpty);

    }, [rowsCount]);

    const handleDelete = (index) => {
        const filtered = rowsCount.filter((_, i) => i !== index);
        setRowsCount(filtered);
    };

    const handleSave = () => {
        const data = {
            user_id: user.id, exercises: {
                [params.day]: {
                    exercises: rowsCount, name, comment, isDone: false
                },
            },
        };
        isNext ? dispatch(createUserExerciseNextMonth(data)).then(res => {
            if (res.payload) {
                navigate(-1)
            }
        }) : dispatch(createUserExercise(data)).then(res => {
            if (res.payload) {
                navigate(-1)
            }
        });
    };

    const updateData = (index, key, value) => {
        const updatedRowsCount = [...rowsCount];
        const updatedExercise = {
            ...updatedRowsCount[index], [key]: value,
        };
        updatedRowsCount[index] = updatedExercise;
        setRowsCount(updatedRowsCount);
    }

    const handleDeleteReq = () => {
        const data = {
            day: +params.day, user_id: params.id
        }
        dispatch(deleteUserExerciseNext(data)).then(res => {
            if (res.payload) {
                navigate(-1)
            }
        })
    }
    return (<UpdateWrapper>
        <PageTitle
            editFunc={isNext ? () => setShowDeleteModal(!showDeleteModal) : showEdit ? () => setShowModal(!showModal) : null}
            editText={isNext ? 'Удалить' : showEdit ? 'Изменить день' : ''}
            title="Тренировки"
            backFunc={() => navigate(-1)}
        />
        <p className="username">{user?.first_name + " " + user?.last_name}</p>
        <br />
        <Input
            label="Название"
            onchange={(e) => {
                setName(e.target.value)
            }}
            value={name}
        />
        <br />

        {rowsCount?.map((item, index) => {
            return (<div key={index} className="item">
                <div className="title">
                    <p>{index + 1} упражнение</p>
                    <p onClick={() => handleDelete(index)}>
                        {rowsCount.length > 1 && "x"}
                    </p>
                </div>
                <div className="inputs">
                    <Select
                        setFieldValue={(name, id) => {
                            if (name && id) {
                                const updatedRowsCount = [...rowsCount];
                                const updatedExercise = {
                                    ...updatedRowsCount[index], exercise_id: id, exercise: name,
                                };

                                updatedRowsCount[index] = updatedExercise;
                                setRowsCount(updatedRowsCount);
                            }
                        }}
                        values={rowsCount}
                        loadMoreBtnFunc={count > exercises?.rows?.length && !search ? () => {
                            dispatch(setExercisePage(page + 1))
                        } : null}
                        searchInputValue={search}
                        searchInputFunc={(e) => {
                            dispatch(setExercisePage(1))
                            dispatch(setExerciseSearch(e.target.value))
                        }}
                        options={exercises?.rows || []}
                        onchange={(e) => {
                        }}
                        value={item.exercise_id}
                        label={"Упражнение"}
                    />
                    <Input
                        label="Подходы"
                        onchange={(e) => {
                            updateData(index, 'approaches', e.target.value.replace(/\D/g, ''))
                        }}
                        value={item.approaches}
                    />
                    <Input
                        label="Количество"
                        onchange={(e) => {
                            updateData(index, 'quantity', e.target.value.replace(/\D/g, ''))
                        }}
                        value={item.quantity}
                    />
                    <Input
                        label="Время"
                        onchange={(e) => {
                            updateData(index, 'time', e.target.value)
                        }}
                        value={item.time}
                    />
                    <div className="radioCont">
                        <label htmlFor="radioCont">Рабочий вес</label>
                        <div>
                            <input
                                type="radio"
                                name={`have_working_weight${index}`}
                                checked={item.have_working_weight}
                                onChange={(e) => updateData(index, 'have_working_weight', true)}
                            />
                            <input
                                type="radio"
                                name={`have_working_weight${index}`}
                                checked={!item.have_working_weight}
                                onChange={(e) => updateData(index, 'have_working_weight', false)}
                            />
                        </div>
                    </div>
                </div>
            </div>);
        })}
        <div
            className="addBtn"
            onClick={() => setRowsCount((prev) => [...prev, macket])}
        >
            Добавить упражнение
        </div>
        <br />
        <br />
        <br />
        <textarea
            placeholder="Комментарий"
            onChange={(e) => {
                setComment(e.target.value)
            }}
            rows={6}
            value={comment}
        />
        <br />
        <button
            disabled={disableBtn || !name}
            className="saveBtn" onClick={handleSave}>
            Сохранить
            <FaAngleRight />
        </button>
        <CalendarModal
            allDays={user?.UserExercise?.all_days}
            nextMonthAllDays={user?.NextMonthUserExercise?.all_days}
            user_id={user?.id}
            open={showModal}
            handleModal={() => setShowModal(false)}
        />
        <DeleteExerciseModal
            handleOk={handleDeleteReq} open={showDeleteModal} handleModal={() => setShowDeleteModal(false)} />
    </UpdateWrapper>);
};

export default ProfileUpdate;
