import styled from "styled-components";

export const BanksModalContent = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  .title{
    width: max-content;
    font-size: 15px;
    color: var(--text1-color);
    text-align: center;
    margin:0 auto 20px auto;
  }
  div{
    display: flex;
    justify-content: center;
    gap: 16px;
    button{
      background: transparent;
      width: max-content;
      margin-top: 20px;
      border: 1px solid var(--text1-color);
      color: var(--text1-color);
      padding: 5px 15px;
      cursor: pointer;
      border-radius: 15px;
    }
  }
  
`;
