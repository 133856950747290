import { ExercisesListWrapper } from "./styled";
import { Link, useNavigate, useParams } from "react-router-dom";
import PageTitle from "../../components/pageTitle";
import SearchInput from "../../components/serachInput";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import {
  deleteExercise, getAllExercisesWithGroup,
  setSerach
} from "../../store/features/exercises/slice";
import {
  selectAllExercies,
  selectExerciesSearch,
} from "../../store/features/exercises/selectors";
import { genders } from "../../constants";
import { selectAllGroups } from "../../store/features/auth/selectors";
import { getGroups } from "../../store/features/auth/slice";
import DeleteExerciseModal from "../../components/modals/deleteExerciseModal";

const ExercisesList = () => {
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const exercises = useSelector(selectAllExercies());
  const search = useSelector(selectExerciesSearch());
  const groups = useSelector(selectAllGroups());
  const [selected, setSelected] = useState(null);
  const [selectedId, setSelectedId] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  useEffect(() => {
    dispatch(getGroups("ALL"));
  }, [dispatch]);

  useEffect(() => {
    setSelected(groups?.rows?.find((i) => i.id === params.id));
  }, [groups, params.id]);

  useEffect(() => {
    dispatch(getAllExercisesWithGroup(params.id));
  }, [params, dispatch, search]);

  useEffect(() => {
    return () => {
      dispatch(setSerach(""));
    };
  }, [dispatch]);

  const handleChangeSerach = (e) => {
    dispatch(setSerach(e.target.value));
  };
  const handleDeleteReq = () => {
    dispatch(deleteExercise(selectedId)).then(res => {
      if (res.payload) {
        setShowDeleteModal(false)
        dispatch(getAllExercisesWithGroup(params.id));
      }
    })
  }
  return (
    <ExercisesListWrapper>
      <PageTitle title="Список упражнений" backFunc={() => navigate(-1)} />
      <div className="gender">
        <p>{selected?.name}</p>
        <p>{genders.find((i) => i.id === selected?.gender)?.name}</p>
      </div>
      <br />
      <SearchInput onchange={handleChangeSerach} />
      <br />
      <Link className="link" to={`/createExercise`}>Добавить упражнение</Link>
      <br />
      {exercises?.rows?.map((item) => {
        return (
          <div key={item.id} onClick={() => navigate(`/exerciseFullInfo/${item.id}`)} className="item">
            <p>
              {item.name}
            </p>
            <div>
              <button onClick={(e) => {
                e.stopPropagation()
                navigate(`/createExercise/${item.id}`)
              }}>Редактировать</button>
              <button onClick={(e) => {
                e.stopPropagation()
                setShowDeleteModal(true)
                setSelectedId(item.id)
              }}>Удалить</button>
            </div>
          </div>
        );
      })}
      <br />
      <br />
      <br />
      <DeleteExerciseModal
        handleOk={handleDeleteReq} open={showDeleteModal} handleModal={() => setShowDeleteModal(false)} />
    </ExercisesListWrapper>
  );
};
export default ExercisesList;