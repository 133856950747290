import styled from "styled-components";

export const StyledTriningFullInfo = styled.div`
    max-width: 340px;
    padding-top: 45px;
    margin: 0 auto;
    min-height: 100vh;
    .plusBtn{
        width: 56px;
        height: 56px;
        margin: 0 auto;
        border-radius: 50%;
        border: 5px solid var(--text1-color);
        color: var(--text1-color);
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 10px;
        cursor: pointer;
        svg{
            font-size: 24px;
        }
    }
    .subtitle{
        color: var(--text1-color);
        font-size: 13px;
        font-weight: 300;
        margin-bottom: 8px;
        max-width: 150px;
        margin: 0 auto;
        text-align: center;
    }
    .gender{
        color: var(--text1-color);
        font-size: 13px;
        font-weight: 300;
        max-width: 150px;
        margin: -10px auto 10px auto;
        text-align: center;
    }
    .item{
        cursor: pointer;
        font-size: 15px;
        border: 2px solid var(--text1-color);
        border-radius: 8px;
        margin-bottom: 18px;
        font-weight: 500;
        color: var(--text1-color);
        display: flex;
        align-items: center;
        padding-left: 12px;
        height: 48px;
    }
`
