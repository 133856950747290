import styled from "styled-components";

export const AddCartWrapper = styled.div`
    max-width: 340px;
    padding-top: 45px;
    margin: 0 auto;
    .subTitle{
        text-decoration: underline;
        font-size: 14px;
        text-align: center;
        cursor: pointer;
        color: var(--text1-color)
    }
    .item{
        cursor: pointer;
        display: flex;
        justify-content: space-between;
        padding-right: 8px;
        font-size: 15px;
        border: 2px solid var(--text1-color);
        border-radius: 8px;
        margin-bottom: 18px;
        font-weight: 500;
        color: var(--text1-color);
        display: flex;
        align-items: center;
        padding-left: 12px;
        height: 48px;
        button{
            border: none;
            text-decoration: underline;
            background-color: transparent;
            color: var(--text1-color);
        }
    }
`;

