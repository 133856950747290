import styled from "styled-components";

export const AddDataWrapper = styled.div`
    max-width: 340px;
    padding-top: 45px;
    margin: 0 auto;
    textarea{
        margin: 16px 0;
        width: 100%;
        outline: none;
        border-radius: 9px;
        border: 2px solid var(--text1-color);
        background-color: transparent;
        padding: 12px  0 0 12px;
        color: var(--text1-color);
        &::placeholder {
            color: var(--text1-color);
            font-size: 16px;
        }
        &:focus {
            color: var(--text1-color);
            font-weight: 500;
        }
        &:-webkit-autofill {
            color: var(--text1-color) !important;
            background-color: transparent !important; 
        }

        &:-webkit-autofill::first-line {
            font-size: 16px;
        }
    }
    button{
        width: 154px;
        height: 48px;
        margin:26px auto 0 auto;
        gap: 8px;
        border-radius: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: transparent;
        color: var(--text1-color);
        border: 2px solid var(--text1-color);
        font-size: 16px;
        font-weight: 700;
    }
`;

