import styled from "styled-components";

export const StyledTechnique = styled.div`
    max-width: 340px;
    padding-top: 45px;
    margin: 0 auto;
    min-height: 100vh;
`

export const StyledTechniqueItem = styled.div`
    display: flex;
    margin-bottom: 10px;
    justify-content: space-between;
    align-items: center;
    border: 2px solid var(--text1-color);
    color: var(--text1-color);
    border-radius: 8px;
    font-size: 15px;
    line-height: 15px;
    font-weight: 500;
    padding: 0 10px;
    width: 100%;
    height: 48px;
`