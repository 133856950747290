import styled from "styled-components";

export const RegistrationWrapper = styled.div`
    max-width: 340px;
    padding-top: 45px;
    margin: 0 auto;
  .title {
    color: var(--text1-color);
    font-weight: 700;
    font-size: 17px;
    text-align: center;
    margin-bottom: 40px;
  }
  .registrForm {
    display: flex;
    flex-direction: column;
    gap: 15px;
    button {
      background-color: var(--text1-color);
      border: none;
      width: 170px;
      color: var(--button-color);
      font-weight: 700;
      font-size: 20px;
      border-radius: 30px;
      height: 46px;
      margin: 10px auto;
    }
  }
`;
