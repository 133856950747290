import styled from "styled-components";

export const BanksModalContent = styled.div`
  position: relative;
  .title{
    width: max-content;
    font-size: 15px;
    color: var(--text1-color);
    text-align: center;
    margin:0 auto 20px auto;
  }
  .flex{
    margin-top: 15px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-content: center;
    align-items: center;
    gap: 20px;
    button{
        cursor: pointer;
        padding: 5px 8px;
        background: transparent;
        color: var(--text1-color);
        border: 1px solid var(--text1-color);
        border-radius: 5px;
        font-size: 13px;
    }
  }
`;
