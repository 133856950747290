import React, { useEffect, useState } from "react";
import { StyledFullInfo } from "./styled";
import PageTitle from "../../../components/pageTitle";
import { useNavigate, useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { selectUserNutrition } from "../../../store/features/nutrition/selectors";
import { getUserNotrition, postUserNotrition } from "../../../store/features/nutrition/slice";
import { getOneUser, updateUser } from "../../../store/features/auth/slice";
import { selectUserById } from "../../../store/features/auth/selectors";
import Input from "../../../components/input";
import NutritionModal from "../../../components/modals/nutritionModal";
import { FaAngleRight } from "react-icons/fa";
import SuccessModal from "../../../components/modals/successModal";

const maket = {
  breakfast: "",
  lunch: "",
  dinner: "",
  snack: "",
}
const FullInfo = () => {
  const navigate = useNavigate();
  const params = useParams()
  const dispatch = useDispatch()
  const data = useSelector(selectUserNutrition())
  const userById = useSelector(selectUserById())
  const [openModal, setOpenModal] = useState('')
  const [edited, setEdited] = useState(false)
  const [showSuccessModal, setShowSuccessModal] = useState(false)
  const [dciData, setDciData] = useState({
    DCI: '',
    Fats: '',
    Carbohydrates: '',
    Proteins: ''
  })
  const [globalData, setGlobalData] = useState(maket)

  useEffect(() => {
    dispatch(getUserNotrition(params?.id))
    dispatch(getOneUser(params?.id))
  }, [params, dispatch])

  useEffect(() => {
    if (data && data?.nutrition) {
      setGlobalData(data?.nutrition)
    } else {
      setGlobalData(maket)
    }
  }, [data])

  useEffect(() => {
    if (userById) {
      setDciData(userById?.dci)
    }
  }, [userById])

  const handleOpenModal = (key) => {
    setOpenModal(key)
  }

  const handleChange = (e, key) => {
    const value = e.target.value.replace(/\D/g, '')
    setDciData({
      ...dciData,
      [key]: value
    })
  }

  useEffect(() => {
    if (JSON.stringify(userById?.dci) !== JSON.stringify(dciData)) {
      setEdited(true)
    } else {
      setEdited(false)
    }
  }, [dciData, userById?.dci]);

  const handleSave = () => {
    const data = {
      user_id: params.id,
      nutrition: globalData
    }
    dispatch(postUserNotrition(data)).then(res => {
      if (res.payload) {
        setShowSuccessModal(true)
        setTimeout(() => {
          setShowSuccessModal(false)
          navigate(-1)
        }, 2000)
      }
    })
  }
  const handleSaveDCI = () => {
    dispatch(updateUser({ id: userById?.id, data: { dci: dciData } })).then(res => {
      if (res.payload) {
        setShowSuccessModal(true)
        dispatch(getOneUser(params?.id))
        setTimeout(() => {
          setShowSuccessModal(false)
        }, 1000)
      }
    })
  }
  const floor = (value) => {
    return String(Math.floor(value))
  }
  return (
    <StyledFullInfo>
      <PageTitle
        title={"План питания"}
        backFunc={() => navigate(-1)}
      />
      <p className="username">{userById?.first_name + ' ' + userById?.last_name}</p>
      <p className="group">Цель: {userById?.group?.name}</p>
      <br />
      <Input type='text' onchange={(e) => handleChange(e, 'DCI')} value={floor(dciData?.DCI)} label='Ккал' />
      <br />
      <Input type='text' onchange={(e) => handleChange(e, 'Proteins')} value={floor(dciData?.Proteins)} label='Белки' />
      <br />
      <Input type='text' onchange={(e) => handleChange(e, 'Fats')} value={floor(dciData?.Fats)} label='Жиры' />
      <br />
      <Input type='text' onchange={(e) => handleChange(e, 'Carbohydrates')} value={floor(dciData?.Carbohydrates)} label='Углеводы' />
      {edited && <button onClick={handleSaveDCI}>Сохранить</button>}
      <br />
      <p className="selectTitle">План питания</p>

      <div className="select" onClick={() => handleOpenModal('breakfast')}>Завтрак</div>
      <div className="select" onClick={() => handleOpenModal('lunch')}>Обед</div>
      <div className="select" onClick={() => handleOpenModal('dinner')}>Ужин</div>
      <div className="select" onClick={() => handleOpenModal('snack')}>Перекус</div>

      <button onClick={handleSave}>
        Сохранить
        <FaAngleRight />
      </button>

      <NutritionModal
        globalData={globalData}
        setGlobalData={setGlobalData}
        title={openModal}
        open={!!openModal}
        handleModal={() => setOpenModal('')}
      />
      <SuccessModal open={showSuccessModal} handleModal={() => setShowSuccessModal(false)} />
    </StyledFullInfo>
  );
};

export default FullInfo;
