import styled from "styled-components";

export const StyledCalendarWrapper = styled.div`
    border: 2px solid var(--text1-color);
    margin: 0 auto 30px auto;
    border-radius: 8px;
    background-color: var(--calendar-back);
    padding: 0px 8px 20px 8px;
    width: 100%;
    .react-calendar__tile {
        position: relative;
    }
    .calendar{
        margin: 0 auto;
        .react-calendar__navigation{
            display: flex;
            justify-content: center;
            button{
                background: transparent
            }
            .react-calendar__navigation__arrow{
                display: none;
            }
            .react-calendar__navigation__label{
                color: var(--text1-color);
                border: none;
                margin: 20px auto;
                font-weight: 500;
                font-size: 15px;
                text-transform: capitalize;
            }
        }
        .react-calendar__month-view__weekdays{
            display: none !important;
        }
       
        .react-calendar__month-view__days{
            display: grid !important;
            gap: 10px;
            grid-template-columns: repeat(7,1fr);
        }
        .item{
            background: transparent;
            color: var(--text1-color);
            font-weight: 500;
            font-size: 15px;
            max-width: 35px;
            border: 1px solid var(--text1-color);
            outline: none;
            width: 35px;
            height: 35px;
            border-radius: 50%;
            margin: 0 !important;
        }
        
  .react-calendar__tile--active{
    background: blue
  }
    }
    .tileItem{
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        justify-content:center;
        align-items: center;
        font-weight: 500;
        cursor: pointer;
        font-size: 15px;
        color: white;
    }
    .react-calendar__tile:disabled{
        border-radius: 50%;
        background-color: transparent !important;
        color: var(--text1-color);
        border: 1px solid var(--text1-color);
        opacity: 0.5;
        max-width: 35px;

        .tileItem{
            background-color: transparent !important;
        }
    }
    .react-calendar__year-view__months{
        display: grid !important;
        gap: 10px;
        grid-template-columns: repeat(4,1fr);
        .item{
            text-transform: capitalize;
            cursor: pointer;
            max-width: 85px;
            width: 100% !important;
            height: 35px  !important;
            display: block;
            border: 1px solid var(--text1-color);
            outline: none;
            border-radius: 10px;
        }
    }
    .react-calendar__year-view__months__month:disabled{
        text-transform: capitalize;
        max-width: 85px;
        width: 100%;
        border: 1px solid var(--text1-color);
        outline: none;
        height: 35px;
        border-radius: 10px;
    }
`