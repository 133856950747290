import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PageTitle from "../../../components/pageTitle";
import { useNavigate, useParams } from "react-router";
import { StyledTriningFullInfo } from "./styled";
import { FiPlus } from "react-icons/fi";
import { genders } from "../../../constants";
import {
  selectAllGroups,
  selectUsersWithGroup,
} from "../../../store/features/auth/selectors";
import {
  getAllUsersWithGroup,
  getGroups,
} from "../../../store/features/auth/slice";

const TriningFullInfo = () => {
  const navigate = useNavigate();
  const params = useParams();
  const dispatch = useDispatch();

  const [selected, setSelected] = useState(null);
  const users = useSelector(selectUsersWithGroup());
  const groups = useSelector(selectAllGroups());

  useEffect(() => {
    dispatch(getAllUsersWithGroup(params.id));
  }, [params, dispatch]);

  useEffect(() => {
    if (!groups) {
      dispatch(getGroups("ALL"));
    }
  }, [params, dispatch, groups]);

  useEffect(() => {
    setSelected(groups?.rows?.find((i) => i.id === params.id));
  }, [dispatch, groups, params.id]);

  return (
    <StyledTriningFullInfo>
      <PageTitle title={selected?.name} backFunc={() => navigate(-1)} />
      <p className="gender">
        {genders.find((i) => i.id === selected?.gender)?.name}
      </p>
      <div
        className="plusBtn"
        onClick={() => {
          navigate(`/exercisesList/${params.id}`);
        }}
      >
        <FiPlus />
      </div>
      <div className="subtitle">Добавить упражнения для всех</div>
      <br />
      {users?.rows?.map((item) => {
        return (
          <div
            key={item.id}
            className="item"
            onClick={() => {
              navigate(`/profile/${item.id}`);
            }}
          >
            {item.first_name + " " + item.last_name}
          </div>
        );
      })}
    </StyledTriningFullInfo>
  );
};

export default TriningFullInfo;
