import styled from "styled-components";

export const StyledProfileWrapper = styled.div`
    max-width: 340px;
    padding-top: 45px;
    margin: 0 auto;
  p {
    color: var(--text1-color);
  }
  .username{
    font-size: 24px;
    font-weight: 700;
    color: var(--text1-color);
    text-align: center;
  }
  .react-calendar__tile {
    position: relative;
  }
  a{
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid var(--text1-color);
    background-color: transparent;
    color: var(--text1-color);
    text-decoration: none;
    width: 200px;
    height: 48px;
    border-radius: 11px;
    margin: 40px auto 0 auto;
    font-weight: 500;
    font-size: 13px;
  }
  
`;
