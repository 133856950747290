import { AddCartWrapper } from "./styled";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getCarts } from "../../store/features/reviews/slice";
import { selectCarts } from "../../store/features/reviews/selectors";
import PageTitle from "../../components/pageTitle";
import AddCartModal from "../../components/modals/banksModal";
import DeleteCartModal from "../../components/modals/deleteCartModal";

const AddCart = () => {
  const navigate = useNavigate()
  const carts = useSelector(selectCarts())
  const dispatch = useDispatch();
  const [openModal, setOpenModal] = useState(false)
  const [selected, setSelected] = useState(null)
  const [showDeleteModal, setShowDeleteModal] = useState(null)

  useEffect(() => {
    dispatch(getCarts());
  }, [dispatch]);

  const handleCloseModal = () => {
    setOpenModal(false)
    setSelected(null)
  }

  return (
    <AddCartWrapper>
      <PageTitle title={'Мои карты'} backFunc={() => navigate(-1)} editText={'Добавить карту'} editFunc={() => setOpenModal(true)} />
      <br /><br /><br />
      {carts?.rows?.length > 0 && carts?.rows?.map(item => {
        return (
          <div
            key={item.id}
            onClick={() => {
              setSelected(item)
              setOpenModal(true)
            }}
            className="item"
          >
            {item.title}
            <button onClick={(e) => {
              e.stopPropagation()
              setShowDeleteModal(item)
            }}>Удалить</button>
          </div>
        )
      })}
      <AddCartModal selected={selected} open={openModal} handleModal={handleCloseModal} />
      <DeleteCartModal selected={showDeleteModal} open={!!showDeleteModal} handleModal={() => setShowDeleteModal(null)} />
    </AddCartWrapper>
  );
};
export default AddCart;
