import styled from "styled-components";

export const StyledWrapper = styled.div`
    width: 100%;
    position: relative;
    overflow: hidden;
    .slick-list{
        height: 100%;
        height: 300px;
        .slick-track{
            display: flex;
            height: 100%;
            div{
                height: 100%;
                img{
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
        }
    }
    .slick-cloned{
        display: none;
    }
    .slick-arrow{
        cursor: pointer;
        position: absolute;
        top: 50%; 
        transform: translateY(-50%);
        z-index: 10;
        width: 30px; 
        height: 30px; 
        background-color: #d1d2d3;
        display: flex !important;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        svg{
            font-size: 24px;
            line-height: 24px;
        }
    }
    .slick-prev {
        left: 10px; 
    }

    .slick-next {
        right: 10px; 
    }
`