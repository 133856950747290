import styled from "styled-components";

export const BanksModalContent = styled.div`
  position: relative;
  .title{
    width: max-content;
    font-size: 15px;
    color: var(--text1-color);
    text-align: center;
    margin:0 auto 20px auto;
  }
  .flex{
    margin-top: 25px;
    display: grid;
    grid-template-columns: 1fr 1fr; 
    grid-template-rows: auto auto;
    justify-items: center; 
    align-items: start; 
    gap: 20px;
    p {
        cursor: pointer;
        display: flex;
        width:  128px;
        text-align: center;
        justify-content: center;
        align-items: center;
        color: var(--text1-color);
        border: 1px solid var(--text1-color);
        border-radius: 25px;
        height: 50px;
        font-size: 13px;
        margin: 0 auto;
        font-weight: 500;
        
    }
    p.selected{
      background: var(--calendar-back);
    }
  }
  button{
    cursor: pointer;
    width: 180px;
    height: 40px;
    margin: 30px auto 0 auto;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    color: var(--text1-color);
    border: none;
    text-decoration: underline;
    text-underline-offset: 5px;
    font-size: 16px;
    font-weight: 700;
    &:disabled{
      color: gray;
      cursor: default;
    }
  }
  
`;
