import React from "react";
import { BanksModalContent } from "./styled";
import Modal from "react-modal";
import { IoIosClose } from "react-icons/io";
import { useParams } from "react-router";

const titleTranslate = {
  Updates: "Обновление",
  InterestingFacts: "Интересный факт",
  ScientificResearch: "Научные исследования",
  ApplicationOperation: "Работа приложения",
  DiscountsAndPromotions: "Скидки и акции",
  QuestionAnswer: "Помощь",
}

const DeleteCustomModal = ({ handleOk, open, handleModal }) => {
  const { type } = useParams();
  Modal.setAppElement("#root");
  const app = document.querySelector(".App");
  const isLightTheme = app?.classList?.value.includes("light");

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      width: "340px",
      background: "var(--background)",
      borderRadius: "21px",
      border: "2px solid var(--text1-color)",
    },
    overlay: {
      background: "transparent",
    },
  };


  return (
    <Modal
      className={isLightTheme ? "light lightModal" : ""}
      isOpen={open}
      onRequestClose={() => handleModal(false)}
      style={customStyles}
    >
      {open && (
        <IoIosClose
          className="modalCloseIcon"
          onClick={() => handleModal(false)}
        />
      )}
      <BanksModalContent>
        <p className="title">Удалить {titleTranslate[type]}</p>
        <div className="flex">
          <button onClick={handleOk}>Да</button>
          <button onClick={handleModal}>Отмена</button>
        </div>
      </BanksModalContent>
    </Modal>
  );
};

export default DeleteCustomModal;
