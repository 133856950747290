import styled from "styled-components";

export const DashboardWrapper = styled.div`
    max-width: 340px;
    padding: 45px 0;
    margin: 0 auto;
`;

export const StyledGrid = styled.div`
  margin-top: 30px;
  display: grid;
  grid-template-columns: repeat(2,minmax(140px ,160px));
  justify-content: center;
  gap: 30px;
  .item {
    position: relative;
    display: flex;
    justify-content: center;
    img{
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    .text {
      position: absolute;
      bottom: 10px;
      font-family: "TT Travels Trl", sans-serif;
      width: max-content;
      max-width: 110px;
      text-align: center;
      left: 50%;
      transform: translateX(-50%);
      font-weight: 700;
      font-size: 15px;
      color: white;
    }
  }
`;