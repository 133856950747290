import React, { useEffect } from "react";
import PageTitle from "../../components/pageTitle";
import { useNavigate } from "react-router";
import { StyledTriningData } from "./styled";
import { useDispatch, useSelector } from "react-redux";
import { getGroups } from "../../store/features/auth/slice";
import { selectAllGroups } from "../../store/features/auth/selectors";

const TriningData = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const groups = useSelector(selectAllGroups());

  useEffect(() => {
    dispatch(getGroups("ALL"));
  }, [dispatch]);

  const males = groups?.rows
    ?.filter((item) => item.gender === "MALE")
    .sort((a, b) => a.name.localeCompare(b.name));
  const females = groups?.rows
    ?.filter((item) => item.gender === "FEMALE")
    .sort((a, b) => a.name.localeCompare(b.name));

  const individs = groups?.rows
    ?.filter((item) => item.gender === "UNKNOWN")
    .sort((a, b) => a.name.localeCompare(b.name));

  return (
    <StyledTriningData>
      <PageTitle title="Тренировки" backFunc={() => navigate(-1)} />
      <br />
      <p className="subtitle">Мужчины</p>
      {males?.map((item) => {
        return (
          <div
            key={item.id}
            className="item"
            onClick={() => {
              navigate(`/trining/${item.id}`);
            }}
          >
            {item.name}
          </div>
        );
      })}
      <p className="subtitle">Женщины</p>
      {females?.map((item) => {
        return (
          <div
            key={item.id}
            className="item"
            onClick={() => {
              navigate(`/trining/${item.id}`);
            }}
          >
            {item.name}
          </div>
        );
      })}
      <p className="subtitle">Индивидуально</p>
      {individs?.map((item) => {
        return (
          <div
            key={item.id}
            className="item"
            onClick={() => {
              navigate(`/trining/${item.id}`);
            }}
          >
            {item.name}
          </div>
        );
      })}
      <br />
      <br />
    </StyledTriningData>
  );
};

export default TriningData;
