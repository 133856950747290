import { useState } from "react";
import { SelectWrapper, StyledDrop, StyledDropItem } from "./styled";
import { IoIosArrowDown } from "react-icons/io";
import { useNavigate, useParams } from "react-router";

const CalendarSelect = ({ options, label }) => {
  const [active, setActive] = useState(false);
  let currentMonth = new Date().getMonth() + 1
  let nextMonth = new Date().getMonth() + 2
  let year = new Date().getFullYear()
  const navigate = useNavigate()
  const params = useParams()

  return (
    <SelectWrapper
      $active={active}
      onClick={() => {
        setActive(!active);
      }}
    >
      <IoIosArrowDown className="arrow" />
      <div className="value">
        <p>{label}</p>
        <StyledDrop onClick={(e) => e.stopPropagation()}>
          {options?.map((item, index) => {
            const date = item.date < 10 ? '0' + item.date : item.date
            let month = item.isNext ? nextMonth : currentMonth
            month = month < 10 ? `0${month}` : month
            return (
              <StyledDropItem
                key={index}
              >
                <div onClick={() =>
                  navigate(`/profile/${params.id}/${item.date}/update?next=${item.isNext}`)}>
                  <p>{date + '.' + month + '.' + year}</p>
                </div>
              </StyledDropItem>
            );
          })}
        </StyledDrop>
      </div>
    </SelectWrapper>
  );
};

export default CalendarSelect;
