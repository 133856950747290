import styled from "styled-components";

export const ModalContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .title{
    width: 100%;
    font-size: 15px;
    color: var(--text1-color);
    text-align: center;
  }
  textarea{
    background: transparent;
    width: 100%;
    resize: none;
    outline: none;
    border: 1px solid var(--text1-color);
    color: var(--text1-color);
    border-radius: 10px;
    margin: 8px 0;
    padding: 8px 12px ;
    &::-webkit-scrollbar {
      display: none;
    }
  }
  button{
    width: max-content;
    cursor: pointer;
    padding: 5px 15px;
    border: 1px solid var(--text1-color);
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    color: var(--text1-color);
    border-radius: 24px;
    margin-top: 10px;
    font-size: 13px;
  }
  
`;
