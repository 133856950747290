import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate,} from "react-router";
import {StyledUsers} from "./styled";
import PageTitle from "../../components/pageTitle";
import {selectAllUsers, selectAllUsersPage} from "../../store/features/auth/selectors";
import {getAllUsers, setUsersPage, clearUsersRows, deleteUser} from "../../store/features/auth/slice";
import SubscribeModal from "./subscribeModal";
import {FaRegTrashAlt} from "react-icons/fa";
import DeleteModal from "./deleteModal";

const Users = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [selected, setSelected] = useState(null);
    const [selectedUserDelete, setSelectedUserDelete] = useState(null);
    const users = useSelector(selectAllUsers());
    const page = useSelector(selectAllUsersPage());

    useEffect(() => {
        dispatch(getAllUsers());
    }, [dispatch, page]);
    useEffect(() => {
        return () => {
            dispatch(clearUsersRows())
            dispatch(setUsersPage(1))
        }
    }, [dispatch]);

    const handleDeleteUser = async() => {
       const res = await dispatch(deleteUser(selectedUserDelete.id))
        if (res.payload?.id){
            setSelectedUserDelete(null)
        }
    }

    return (<StyledUsers>
        <PageTitle title='Пользователи' backFunc={() => navigate(-1)}/>
        <br/>
        {users?.rows?.map((item) => {
            return (<div
                onClick={() => navigate(`/user/${item.id}`)}
                key={item.id}
                className="item"
            >
                {item.first_name + " " + item.last_name}
                <div className={'d-flex'}>
                    <div className={`${item.subscription_status} check`}
                         onClick={(e) => {
                             e.stopPropagation()
                             if (item.subscription_status === 'ACTIVE' || item.subscription_status === 'PENDING' || item.subscription_status === 'ACTIVE_PENDING') {
                                 setSelected(item)
                             }
                         }}
                    ></div>
                    <FaRegTrashAlt fill={'red'} onClick={(e) => {
                        e.stopPropagation()
                        setSelectedUserDelete(item)
                    }}/>
                </div>
            </div>);
        })}
        {users.count > users.rows.length &&
            <button className={'loadMoreBtn'} onClick={() => dispatch(setUsersPage(page + 1))}>Ещё</button>}
        <SubscribeModal open={!!selected} selected={selected} handleModal={() => setSelected(null)}/>
        <DeleteModal open={!!selectedUserDelete} selected={selectedUserDelete} onOk={handleDeleteUser}
                        handleModal={() => setSelectedUserDelete(null)}/>
    </StyledUsers>);
};

export default Users;
