import React from "react";
import {ModalContent} from "./styled";
import Modal from "react-modal";
import {IoIosClose} from "react-icons/io";

const DeleteModal = ({open, handleModal, selected, onOk}) => {
    Modal.setAppElement("#root");
    const app = document.querySelector(".App");
    const isLightTheme = app?.classList?.value.includes("light");
    const customStyles = {
        content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            width: "340px",
            paddingTop: '50px',
            background: "var(--background)",
            borderRadius: "21px",
            border: "2px solid var(--text1-color)",
        }, overlay: {
            background: "transparent",
        },
    };

    return (<Modal
        className={isLightTheme ? "light lightModal" : ""}
        isOpen={open}
        onRequestClose={() => handleModal(false)}
        style={customStyles}
    >
        {open && (<IoIosClose
            className="modalCloseIcon"
            onClick={() => handleModal(false)}
        />)}
        <ModalContent>
            <div className="title">
                <p>Удалить пользователя ?</p>
                <br/>
                {selected?.first_name}{' '}
                {selected?.last_name}
            </div>
            <br/>

            <div className="flex">
                <button onClick={onOk}>Принять</button>
                <button onClick={handleModal}>Отменить</button>
            </div>
        </ModalContent>
    </Modal>);
};

export default DeleteModal;
