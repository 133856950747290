import { useEffect } from "react";
import LineChart from "../../components/lineChart";
import PageTitle from "../../components/pageTitle";
import { MyDataWrapper } from "./styled";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { selectUserWeightProgress, selectUserWorkingWeights } from "../../store/features/auth/selectors";
import { getWeightProgress, getWorkingWeight } from "../../store/features/auth/slice";

const UserProgress = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();
  const { id } = params
  const weightProgress = useSelector(selectUserWeightProgress())
  const workingWeights = useSelector(selectUserWorkingWeights())
  useEffect(() => {
    dispatch(getWeightProgress(id))
    dispatch(getWorkingWeight(id))
  }, [dispatch, id])

  return (
    <MyDataWrapper>
      <PageTitle title="Прогресс" backFunc={() => navigate(-1)} />

      <br />
      <br />
      {weightProgress?.weights ?
        <LineChart
          dataSource={Object.values(weightProgress?.weights)}
          labels={Object.keys(weightProgress?.weights)}
          yTitle="Вес/кг"
        />
        : ""}
      <br />
      <br />
      <br />
      {workingWeights?.userExercise ?
        <LineChart
          dataSource={Object.values(workingWeights?.userExercise)}
          labels={Object.keys(workingWeights?.userExercise)}
          yTitle="Тоннаж/кг"
        />
        : ''}
      <br />
      <br />
      <br />
    </MyDataWrapper>
  );
};
export default UserProgress;
