import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { client } from "../../../api";

const initialState = {
    loading: false,
    exercises: {
        rows: [],
        page: 1,
        count: 0,
        search: ''
    },
    exerciseById: null
};

export const getAllExercisesWithGroup = createAsyncThunk(
    "exercise/getAllExercisesWithGroup",
    async (_, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const page = state.exercise.exercises.page
            const search = state.exercise.exercises.search
            const response = await client.post(`/exercise`, {
                page,
                limit: 10,
                search
            });

            return response.data.payload;
        } catch (err) {
            rejectWithValue(err);
        }
    }
);
export const createUserExercise = createAsyncThunk(
    "exercise/createUserExercise",
    async (data, { rejectWithValue }) => {
        try {
            const response = await client.post(`/exercise/addUserExercise`, data);

            return response.data.payload;
        } catch (err) {
            rejectWithValue(err);
        }
    }
);
export const createUserExerciseNextMonth = createAsyncThunk(
    "exercise/createUserExerciseNextMonth",
    async (data, { rejectWithValue }) => {
        try {
            const response = await client.post(`/next_month_exercise/addUserExercise`, data);

            return response.data.payload;
        } catch (err) {
            rejectWithValue(err);
        }
    }
);
export const deleteUserExerciseNext = createAsyncThunk(
    "exercise/deleteUserExerciseNext",
    async (data, { rejectWithValue }) => {
        try {
            const response = await client.post(`/next_month_exercise/deleteUserExerciseDay`, data);

            return response.data.payload;
        } catch (err) {
            rejectWithValue(err);
        }
    }
);
export const createExercise = createAsyncThunk(
    "exercise/createExercise",
    async (data, { rejectWithValue }) => {
        try {
            const response = await client.post(`/exercise/add`, data);

            return response.data.payload;
        } catch (err) {
            rejectWithValue(err);
        }
    }
);
export const editExercise = createAsyncThunk(
    "exercise/editExercise",
    async ({ id, data }, { rejectWithValue }) => {
        try {
            const response = await client.patch(`/exercise/${id}`, data);

            return response.data.payload;
        } catch (err) {
            rejectWithValue(err);
        }
    }
);
export const deleteExercise = createAsyncThunk(
    "exercise/editExercise",
    async (id, { rejectWithValue }) => {
        try {
            const response = await client.delete(`/exercise/${id}`);

            return response.data.payload;
        } catch (err) {
            rejectWithValue(err);
        }
    }
);
export const getOneExercise = createAsyncThunk(
    "exercise/getOneExercise",
    async (id, { rejectWithValue }) => {
        try {
            const response = await client.get(`/exercise/${id}`);

            return response.data.payload;
        } catch (err) {
            rejectWithValue(err);
        }
    }
);
export const changeExerciesDay = createAsyncThunk(
    "exercise/changeExerciesDay",
    async (data, { rejectWithValue }) => {
        try {
            const response = await client.patch(`/exercise/updateUserExerciseDay`, data);

            return response.data.payload;
        } catch (err) {
            rejectWithValue(err);
        }
    }
);


const exerciseSlice = createSlice({
    name: "exercise",
    initialState,
    reducers: {
        setSerach: (state, action) => {
            state.exercises.search = action.payload
        },
        creatExerciseById: (state) => {
            state.exerciseById = null
        }
    },
    extraReducers(builder) {
        builder.addCase(getAllExercisesWithGroup.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(getAllExercisesWithGroup.fulfilled, (state, action) => {
            state.exercises.rows = action.payload?.rows
            state.exercises.count = action.payload?.count
            state.loading = false;
        });
        builder.addCase(getAllExercisesWithGroup.rejected, (state) => {
            state.loading = false;
        });
        builder.addCase(createUserExercise.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(createUserExercise.fulfilled, (state) => {
            state.loading = false;
        });
        builder.addCase(createUserExercise.rejected, (state) => {
            state.loading = false;
        });
        builder.addCase(createExercise.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(createExercise.fulfilled, (state) => {
            state.loading = false;
        });
        builder.addCase(createExercise.rejected, (state) => {
            state.loading = false;
        });
        builder.addCase(getOneExercise.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(getOneExercise.fulfilled, (state, action) => {
            state.exerciseById = action.payload
            state.loading = false;
        });
        builder.addCase(getOneExercise.rejected, (state) => {
            state.loading = false;
        });
    },
});


export const { setSerach, creatExerciseById } = exerciseSlice.actions
export default exerciseSlice.reducer;
