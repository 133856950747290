import {NewsWrapper,} from "./styled";
import {useNavigate, useParams} from "react-router-dom";
import PageTitle from "../../../components/pageTitle";
import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import Select from "../../../components/select";
import Input from "../../../components/input";
import {
    clearbyIdCutom, deleteUploadedFile, editCustom, getOneCustom, postCustom, uploadFile
} from "../../../store/features/reviews/slice";
import {selectCustomById} from "../../../store/features/reviews/selectors";
import {useMemo} from "react";
import {toUnderlineModel} from "../singlePage";
import {compressImage} from "../../../components/compressImage";

const AddNews = () => {

    const categoryOptions = useMemo(() => [{id: 'Updates', name: 'Обновление'}, {
        id: 'InterestingFacts', name: 'Интересные факты'
    }, {id: 'ScientificResearch', name: 'Научные исследования'}, {
        id: 'ApplicationOperation', name: 'Работа приложения'
    }, {id: 'DiscountsAndPromotions', name: 'Скидки и акции'}, {id: 'QuestionAnswer', name: 'FAQ'}], []);

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const params = useParams()
    const [selectedCateg, setSelectedCateg] = useState(null)
    const [title, setTitle] = useState('')
    const [description, setDescription] = useState('')
    const byId = useSelector(selectCustomById())
    const [files, setFiles] = useState([]);

    useEffect(() => {
        if (byId) {
            setTitle(byId.title)
            setDescription(byId.description)
            setFiles(byId[toUnderlineModel[params?.type]])
        }
    }, [byId, dispatch, categoryOptions])


    useEffect(() => {
        if (params.id) {
            const data = {
                id: params?.id, model: params.type
            }
            dispatch(getOneCustom(data))
            setSelectedCateg(categoryOptions.find(i => i.id === params?.type)?.id)
        }
        return () => {
            dispatch(clearbyIdCutom())
        }
    }, [params])

    const handleFileChange = async (event, index) => {
        const selectedFile = event.target.files[0];
        if (selectedFile) {
            const formData = new FormData()
            formData.append('model', selectedCateg)
            const compressedFile = await compressImage(selectedFile)
            formData.append('file', compressedFile)
            const res = await dispatch(uploadFile(formData))
            if (res.payload) {
                setFiles(prev => [...files, res.payload]);
            }
        }
    };

    const handlePrevClick = (id) => {
        setFiles(prev => prev.filter(i => i.id !== id));
        dispatch(deleteUploadedFile(id))
        return
    }


    const handleSave = () => {
        const data = {
            title, description, model: selectedCateg, files: files.map(file => file.id)
        }

        if (params.id) {
            dispatch(editCustom({id: params?.id, body: data})).then(res => {
                if (res.payload) {
                    navigate(-1)
                }
            })
        } else {
            dispatch(postCustom(data)).then(res => {
                if (res.payload) {
                    navigate(-1)
                }
            })
        }
    }

    return (<NewsWrapper>
        <PageTitle
            title={params.id ? "Редактировать новость" : "Добавить новость"}
            backFunc={() => navigate(-1)}
        />
        <Select
            label={'Категория'}
            options={categoryOptions}
            value={selectedCateg}
            onchange={(e) => {
                if (!params?.id) {
                    setSelectedCateg(e.id)
                }
            }}
            setFieldValue={() => {
            }}
        />
        <br/>
        <Input
            type="text"
            value={title}
            label="Заголовок"
            onchange={(e) => {
                setTitle(e.target.value)
            }}
            name="title"
        />
        <br/>
        <textarea
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            name="description"
            rows="10"
            placeholder="Текст новости"
        />
        <br/>
        {selectedCateg === 'QuestionAnswer' ? "" : !files?.length ? '' : <div className="grid">
            {files.map((file, index) => {
                return (<div key={index}>
                    <div className="preview" onClick={() => {
                        if (!selectedCateg) {
                            alert('Выберите категорию')
                            return
                        }
                        handlePrevClick(file.id)
                    }}>
                        <img
                            src={file.url}
                            alt="Preview"
                        />
                    </div>

                </div>)
            })}
        </div>}
        <div className="grid">
            <div className="preview" onClick={() => {
                if (!selectedCateg) {
                    alert('Выберите категорию')
                    return
                }
                document.getElementById(`fileInput`).click()
            }}>
                <p>Нажмите для выбора файла</p>
                <input
                    type="file"
                    id={`fileInput`}
                    style={{display: 'none'}}
                    onChange={(e) => handleFileChange(e)}
                />
            </div>
        </div>
        <br/>
        <button disabled={!selectedCateg || !title || !description}
                onClick={handleSave}>{params?.id ? "Сохранить" : 'Добавить'}</button>
    </NewsWrapper>);
};
export default AddNews;
