import { configureStore } from "@reduxjs/toolkit";
import authSlice from "./features/auth/slice";
import techniqueSlice from "./features/technique/slice";
import exerciseSlice from "./features/exercises/slice";
import nutritionSlice from "./features/nutrition/slice";
import reviewSlice from "./features/reviews/slice";

export const store = configureStore({
    reducer: {
        auth: authSlice,
        exercise: exerciseSlice,
        technique: techniqueSlice,
        nutrition: nutritionSlice,
        review: reviewSlice,
    },
});
