import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { client } from "../../../api";
import { getOneUser } from "../auth/slice";

const initialState = {
    loading: false,
    userNutrition: null,
    userNutritionDCI: null
};

export const getUserNotrition = createAsyncThunk(
    "exercise/getUserNotrition",
    async (id, { rejectWithValue }) => {
        try {
            const response = await client.get(`/nutrition/${id}`);

            return response.data.payload;
        } catch (err) {
            rejectWithValue(err);
        }
    }
);

export const getUserDCI = createAsyncThunk(
    "exercise/getUserDCI",
    async (id, { rejectWithValue }) => {
        try {
            const response = await client.get(`/nutrition/getUserDCIAdmin/${id}`);

            return response.data.payload;
        } catch (err) {
            rejectWithValue(err);
        }
    }
);
export const postUserNotrition = createAsyncThunk(
    "exercise/postUserNotrition",
    async (data, { rejectWithValue, dispatch }) => {
        try {
            const response = await client.post(`/nutrition/addUserNutrition`, data);
            if (response.data.payload) {
                dispatch(getOneUser(data.user_id))

            }
            return response.data.payload;
        } catch (err) {
            rejectWithValue(err);
        }
    }
);

const nutritionSlice = createSlice({
    name: "nutrition",
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder.addCase(getUserNotrition.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(getUserNotrition.fulfilled, (state, action) => {
            state.loading = false;
            state.userNutrition = action.payload;
        });
        builder.addCase(getUserNotrition.rejected, (state) => {
            state.loading = false;
        });
        builder.addCase(getUserDCI.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(getUserDCI.fulfilled, (state, action) => {
            state.loading = false;
            state.userNutritionDCI = action.payload;
        });
        builder.addCase(getUserDCI.rejected, (state) => {
            state.loading = false;
        });
    },
});


export const { setSerach } = nutritionSlice.actions
export default nutritionSlice.reducer;
