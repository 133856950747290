import { StyledFullInfo } from "./styled";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import PageTitle from "../../../components/pageTitle";
import { getOneExercise } from "../../../store/features/exercises/slice";
import { selectExerciseById } from "../../../store/features/exercises/selectors";
import { ExercisesListWrapper } from "../styled";

const ExerciseFullInfo = () => {
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const data = useSelector(selectExerciseById());

  useEffect(() => {
    dispatch(getOneExercise(params.id));
  }, [dispatch, params]);

  return (
    <ExercisesListWrapper>
      <PageTitle title="Техника упражнений" backFunc={() => navigate(-1)} />
      <StyledFullInfo>
        <p className="name">{data?.name}</p>
        <div className="text">{data?.description}</div>
        {data?.video_url.includes('iframe') ?
          <div style={{ position: 'relative' }} dangerouslySetInnerHTML={{ __html: data?.video_url }}></div>
          : ''}
      </StyledFullInfo>
    </ExercisesListWrapper>
  );
};
export default ExerciseFullInfo;
