import { useRef } from "react";
import { InputWrapper } from "./styled";

const Input = ({ type, name, value, label, onchange, readonly }) => {
  const active = value?.length > 0;
  const ref = useRef(null);
  return (
    <InputWrapper $active={active}>
      <label
        htmlFor={name}
        onClick={() => {
          ref.current?.focus();
        }}
      >
        {label}
      </label>
      <input
        readOnly={readonly}
        ref={ref}
        name={name}
        type={type}
        onChange={onchange}
        value={value}
      />
    </InputWrapper>
  );
};

export default Input;
