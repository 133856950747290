import styled from "styled-components";

export const StyledFullInfo = styled.div`
    padding: 45px 0;
    margin: 0 auto;
    max-width: 410px;
    .container{
        width: calc(100% + 48px);
        margin-left: -24px;
        display: flex;
        flex-direction: column;
        height: fit-content;
        position: relative;
    }
    .name{
        padding: 0 24px;
        text-align: center;
        color: var(--text1-color);
        margin-top: -15px;
        font-size: 17px;
        font-weight: 500;
        line-height: 17px;
    }
    .text{
        padding: 0 24px;
        margin-top: 20px;
        font-size: 15px;
        font-weight: 500;
        color: var(--text1-color);
        margin-bottom: 20px;
    }
    iframe{
        position: absolute;
        height: 300px;
        left: 0;
        transform: translateY(100%);
        bottom: 0;
        right: 0;
        border: none;
        width: 100%;
    }
`;
