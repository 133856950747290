import styled from "styled-components";

export const ExercisesListWrapper = styled.div`
    max-width: 340px;
    padding-top: 45px;
    margin: 0 auto;
    .link{
        text-decoration: none;
        width: 200px;
        height: 40px;
        margin: 0 auto;
        border-radius: 11px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: transparent;
        color: var(--text1-color);
        border: 2px solid var(--text1-color);
        font-size: 15px;
        font-weight: 500;
    }
    .item{
        cursor: pointer;
        font-size: 15px;
        border: 2px solid var(--text1-color);
        border-radius: 8px;
        margin-bottom: 18px;
        font-weight: 500;
        color: var(--text1-color);
        display: flex;
        align-items: center;
        padding-left: 12px;
        height: 48px;
        width: 100%;
        justify-content: space-between;
        padding-right: 8px;
        a{
            color: var(--text1-color);
            text-decoration: none;
        }
        button{
            background: transparent;
            color: var(--text1-color);
            border: none;
            cursor: pointer;
            &:nth-child(1){
                text-decoration: underline;
                margin-right: 10px;
            }
        }
    }
    .gender{
        color: var(--text1-color);
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: 13px;
        font-weight: 300;
        max-width: 150px;
        margin: -15px auto 10px auto;
        text-align: center;
        p:nth-child(1){
            font-size: 16px;
            margin-bottom: 8px;
        }
    }
`;

