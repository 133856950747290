export const selectAuthLoading = () => (state) => state.auth.loading;
export const selectAllGroups = () => (state) => state.auth.groups;

export const selectUsersWithGroup = () => (state) => state.auth.usersWithGroup;
export const selectUserById = () => (state) => state.auth.userById?.one;
export const selectAllUsers = () => (state) => state.auth.allUsers;
export const selectAllUsersPage = () => (state) => state.auth.allUsers.page;
export const selectUserWeightProgress = () => (state) => state.auth.weightProgress;
export const selectUserWorkingWeights = () => (state) => state.auth.workingWeights;
export const selectExercisePage = () => (state) => state.auth.exercises?.page;
export const selectUserExercises = () => (state) => state.auth.exercises;
export const selectUserExercisesCount = () => (state) => state.auth.exercises?.count;
export const selectUserExercisesSearch = () => (state) => state.auth.exercises?.search;