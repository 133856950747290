import { AddDataWrapper } from "./styled";
import { useNavigate, useParams } from "react-router-dom";
import PageTitle from "../../../components/pageTitle";
import { FaAngleRight } from "react-icons/fa";
import { useEffect, useState } from "react";
import Input from "../../../components/input";
import { useDispatch, useSelector } from "react-redux";
import { creatExerciseById, createExercise, editExercise, getOneExercise } from "../../../store/features/exercises/slice";
import { selectExerciseById } from "../../../store/features/exercises/selectors";

const CreateExercise = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();
  const byId = useSelector(selectExerciseById())
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [video, setVideo] = useState("");
  useEffect(() => {
    if (params.id) {
      dispatch(getOneExercise(params.id))
    }
  }, [params.id, dispatch])

  useEffect(() => {
    return () => {
      setName()
      setDescription()
      setVideo()
      dispatch(creatExerciseById())
    }
  }, [dispatch])

  useEffect(() => {
    if (byId) {
      setName(byId.name)
      setDescription(byId.description)
      setVideo(byId.video_url)
    }
  }, [byId])

  const handleSave = () => {
    const data = {
      name,
      description,
      video_url: video,
    };
    if (params.id) {
      dispatch(editExercise({ id: params.id, data })).then((res) => {
        if (res.payload) {
          navigate(-1);
        }
      });
    } else {
      dispatch(createExercise(data)).then((res) => {
        if (res.payload) {
          navigate(-1);
        }
      });
    }
  };

  return (
    <AddDataWrapper>
      <PageTitle title="Техника упражнений" backFunc={() => navigate(-1)} />

      <Input
        label="Название упражнения"
        value={name}
        onchange={(e) => setName(e.target.value)}
      />
      <textarea
        onResize={() => { }}
        placeholder="Описание упражнения"
        cols="30"
        rows="10"
        value={description}
        onChange={(e) => {
          setDescription(e.target.value);
        }}
      />
      <br />
      <Input
        label="Прикрепить видео"
        value={video}
        onchange={(e) => setVideo(e.target.value)}
      />
      <button onClick={handleSave}>
        Сохранить
        <FaAngleRight />
      </button>
    </AddDataWrapper>
  );
};
export default CreateExercise;
