import React, { useEffect, useState } from "react";
import { BanksModalContent } from "./styled";
import Modal from "react-modal";
import { IoIosClose } from "react-icons/io";
import Input from "../../input";
import { useDispatch } from "react-redux";
import { addCart, editCart } from "../../../store/features/reviews/slice";

const AddCartModal = ({ selected, open, handleModal }) => {
  Modal.setAppElement("#root");
  const app = document.querySelector(".App");
  const isLightTheme = app?.classList?.value.includes("light");
  const dispatch = useDispatch()

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      width: "340px",
      background: "var(--background)",
      borderRadius: "21px",
      border: "2px solid var(--text1-color)",
    },
    overlay: {
      background: "transparent",
    },
  };

  const [title, setTitle] = useState('')
  const [amount, setAmount] = useState('')
  const [name, setName] = useState('')
  const [description, setDescription] = useState('')

  useEffect(() => {
    if (open && selected) {
      setTitle(selected?.title)
      setDescription(selected?.description)
      setAmount(selected?.amount)
      setName(selected?.name)
    } else {
      setTitle('')
      setName('')
      setAmount('')
      setDescription('')
    }
  }, [open, selected])

  const handleSave = () => {
    const data = {
      title,
      description,
      name,
      amount: amount
    }
    if (selected) {
      dispatch(editCart({ id: selected?.id, data })).then(res => {
        if (res.payload) {
          handleModal()
        }
      })
      return
    }
    dispatch(addCart(data)).then(res => {
      if (res.payload) {
        handleModal()
      }
    })
  }
  return (
    <Modal
      className={isLightTheme ? "light lightModal" : ""}
      isOpen={open}
      onRequestClose={() => handleModal(false)}
      style={customStyles}
    >
      {open && (
        <IoIosClose
          className="modalCloseIcon"
          onClick={() => handleModal(false)}
        />
      )}
      <BanksModalContent>
        <div className="title">Добавить карту</div>
        <br />
        <br />
        <Input
          label={'Название Банка'}
          onchange={(e) => setTitle(e.target.value)}
          value={title}
        />
        <br />
        <Input
          label={'Номер карты'}
          onchange={(e) => setDescription(e.target.value.replace(/\D/g, ''))}
          value={description}
        />
        <br />
        <Input
          label={'Имя и фамилия на карте'}
          onchange={(e) => setName(e.target.value)}
          value={name}
        />
        <br />
        <Input
          label={'Сумма за месяц'}
          onchange={(e) => setAmount(e.target.value.replace(/\D/g, ''))}
          value={amount}
        />
        <br />
        <br />
        <button disabled={!title || !description} onClick={handleSave}>Сохранить</button>
      </BanksModalContent>
    </Modal>
  );
};

export default AddCartModal;
