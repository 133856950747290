import styled from "styled-components";
import { StyledCalendarWrapper } from "../../../components/calendar/styled";

export const UpdateWrapper = styled.div`
    max-width: 340px;
    padding-top: 45px;
    margin: 0 auto;

    .username {
        font-size: 24px;
        font-weight: 700;
        color: var(--text1-color);
        text-align: center;
    }

    .item {
        margin-top: 20px;

        .title {
            padding-right: 8px;
            display: flex;
            justify-content: space-between;
            color: var(--text1-color);
            font-size: 13px;
            font-weight: 500;
            margin-bottom: 8px;

            p:nth-child(2) {
                font-size: 16px;
                line-height: 13px;
                cursor: pointer;
            }
        }

        .inputs {
            display: flex;
            flex-direction: column;
            gap: 16px;

            .radioCont {
                display: flex;
                justify-content: space-between;
                align-items: center;
                color: var(--text1-color);
                border: 2px solid var(--text1-color);
                border-radius: 9px;
                position: relative;
                gap: 20px;
                height: 48px;
                width: 100%;
                padding: 0 12px;
                align-items: center;

                label {
                    width: max-content;
                }

                div {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    gap: 10px;

                    input {
                        width: 20px;
                        height: 20px;

                        &:nth-child(1) {
                            &:checked:after {
                                background-color: #0FBE00;
                            }
                        }

                        &:nth-child(2) {
                            &:checked:after {
                                background-color: red;
                            }
                        }
                    }

                    input[type='radio']:after {
                        width: 15px;
                        cursor: pointer;
                        height: 15px;
                        border-radius: 15px;
                        top: 0;
                        left: 0;
                        position: relative;
                        content: '';
                        display: inline-block;
                        visibility: visible;
                        border: 2px solid white;
                    }

                    input[type='radio']:checked:after {
                        width: 15px;
                        height: 15px;
                        border-radius: 15px;
                        top: 0;
                        left: 0;
                        position: relative;
                        content: '';
                        display: inline-block;
                        visibility: visible;
                        border: 2px solid white;
                    }
                }
            }
        }
    }

    .addBtn {
        color: var(--text1-color);
        font-size: 13px;
        font-weight: 500;
        margin-top: 16px;
        text-decoration: underline;
        width: max-content;
        cursor: pointer;
    }

    .saveBtn {
        width: 154px;
        height: 48px;
        margin: 25px auto 0 auto;
        gap: 8px;
        border-radius: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: transparent;
        color: var(--text1-color);
        cursor: pointer;
        border: 2px solid var(--text1-color);
        font-size: 16px;
        font-weight: 700;

        &:disabled {
            cursor: default;
            color: gray;
            border: 2px solid gray;
        }

    }

    textarea {
        background: transparent;
        width: 100%;
        resize: none;
        outline: none;
        border: 2px solid var(--text1-color);
        color: var(--text1-color);
        border-radius: 10px;
        margin: 8px 0;
        padding: 8px 12px;

        &::-webkit-scrollbar {
            display: none;
        }

        &::placeholder {
            color: var(--text1-color);
        }
    }

    ${StyledCalendarWrapper} {
        width: 100%;
        background-color: transparent;
        border: none;
        margin: 0 auto;

        .calendar .item {
            width: 35px;
            height: 35px;
            cursor: pointer;
        }
    }
`;
