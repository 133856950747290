import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {client} from "../../../api";

const initialState = {
    loading: false, groups: null, usersWithGroup: {
        rows: [], page: 1, count: 0
    }, exercises: {
        rows: [], page: 1, count: 0, search: ''
    }, userById: null, weightProgress: null, workingWeights: null, allUsers: {
        rows: [], page: 1, count: 0
    },
};

export const updateUser = createAsyncThunk("auth/updateUser", async ({id, data}, {rejectWithValue}) => {
    try {
        const response = await client.put(`/users/${id}`, data);

        return response.data.payload;
    } catch (err) {
        rejectWithValue(err);
    }
});
export const updateUserGroup = createAsyncThunk("auth/updateUserGroup", async ({id, data}, {rejectWithValue}) => {
    try {
        const response = await client.put(`/users/${id}`, data);

        return response.data.payload;
    } catch (err) {
        rejectWithValue(err);
    }
});
export const updateUserSubscription = createAsyncThunk("auth/updateUserSubscription", async ({
                                                                                                 id, data
                                                                                             }, {rejectWithValue}) => {
    try {
        const response = await client.put(`/users/${id}`, data);

        return response.data.payload;
    } catch (err) {
        rejectWithValue(err);
    }
});

export const getAllUsers = createAsyncThunk("auth/getAllUsers", async (_, {getState, rejectWithValue}) => {
    try {
        const state = getState()
        const page = state.auth.allUsers.page
        const response = await client.post(`/users/get-all`, {
            page, limit: 10
        });

        return response.data.payload;
    } catch (err) {
        rejectWithValue(err);
    }
});
export const deleteUser = createAsyncThunk("auth/deleteUser", async (id, {getState, rejectWithValue, dispatch}) => {
    try {
        const state = getState()
        const users = state.auth.allUsers
        const response = await client.delete(`/users/${id}`,);
        if (response.data.payload) {
            const filtered = users.rows.filter(i => i.id !== id)
            dispatch(setUsers(filtered))
        }
        return response.data.payload;
    } catch (err) {
        rejectWithValue(err);
    }
});
export const getGroups = createAsyncThunk("auth/getGroups", async (type, {rejectWithValue}) => {
    try {
        const response = await client.get(`/group/get-all/${type}`);

        return response.data.payload;
    } catch (err) {
        rejectWithValue(err);
    }
});
export const getAllUsersWithGroup = createAsyncThunk("auth/getAllUsersWithGroup", async (id, {
    rejectWithValue, getState
}) => {
    try {
        const state = getState()
        const page = state.auth.usersWithGroup.page
        const response = await client.post(`/users/get-all`, {
            page, limit: 10, group_id: id
        });

        return response?.data.payload;
    } catch (err) {
        rejectWithValue(err);
    }
});
export const getOneUser = createAsyncThunk("auth/getOneUser", async (id, {rejectWithValue}) => {
    try {
        const response = await client.get(`/users/${id}`);

        return response.data.payload;
    } catch (err) {
        rejectWithValue(err);
    }
});
export const onLogin = createAsyncThunk("auth/onLogin", async (data, {rejectWithValue}) => {
    try {
        const response = await client.post('/auth/loginAdmin', data)
        if (response?.data?.payload) {
            localStorage.setItem('token', response.data.payload.telegram_user_id)
        }
        return response.data.payload;
    } catch (err) {
        rejectWithValue(err);
    }
});
export const getWeightProgress = createAsyncThunk("auth/getWeightProgress", async (id, {rejectWithValue}) => {
    try {
        const response = await client.get(`/auth/getWeightAdmin/${id}`)

        return response.data.payload;
    } catch (err) {
        rejectWithValue(err);
    }
});
export const getWorkingWeight = createAsyncThunk("auth/getWorkingWeight", async (id, {rejectWithValue}) => {
    try {
        const response = await client.get(`/exercise/getWorkingWeightsAdmin/${id}`)

        return response.data.payload;
    } catch (err) {
        rejectWithValue(err);
    }
});
export const getAllExercisesForUser = createAsyncThunk("auth/getAllExercisesForUser", async (_, {
    rejectWithValue, getState
}) => {
    try {
        const state = getState()
        const page = state.auth.exercises.page
        const search = state.auth.exercises.search
        const response = await client.post(`/exercise`, {
            page, limit: 10, search
        });

        return response.data.payload;
    } catch (err) {
        rejectWithValue(err);
    }
});

const authSlice = createSlice({
    name: "auth", initialState, reducers: {
        clearUserById: (state) => {
            state.userById = null
        }, setUsers: (state, action) => {
            state.allUsers.rows = action.payload
            state.allUsers.count = state.allUsers.count - 1
        }, setExercisePage: (state, action) => {
            state.exercises.page = action.payload
        }, setUsersPage: (state, action) => {
            state.allUsers.page = action.payload
        }, clearUsersRows: (state, action) => {
            state.allUsers.rows = []
        }, clearExerciseRows: (state) => {
            state.exercises.rows = []
        }, setExerciseSearch: (state, action) => {
            state.exercises.search = action.payload
        }
    }, extraReducers(builder) {
        builder.addCase(getAllUsersWithGroup.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(getAllUsersWithGroup.fulfilled, (state, action) => {
            state.usersWithGroup.rows = action.payload?.rows
            state.usersWithGroup.count = action.payload?.count
            state.loading = false;
        });
        builder.addCase(getAllUsersWithGroup.rejected, (state) => {
            state.loading = false;
        });
        builder.addCase(getGroups.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(getGroups.fulfilled, (state, action) => {
            state.loading = false;
            state.groups = action.payload
        });
        builder.addCase(getGroups.rejected, (state) => {
            state.loading = false;
        });
        builder.addCase(getOneUser.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(getOneUser.fulfilled, (state, action) => {
            state.loading = false;
            state.userById = action.payload
        });
        builder.addCase(getOneUser.rejected, (state) => {
            state.loading = false;
        });
        builder.addCase(getAllUsers.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(getAllUsers.fulfilled, (state, action) => {
            state.loading = false;
            state.allUsers.count = action.payload.count
            if (state.allUsers.rows.length) {
                state.allUsers.rows = [...state.allUsers.rows, ...action.payload.rows]
            } else {
                const existingIds = new Set(state.allUsers.rows.map(row => row.id)); // Предполагается, что у вас есть уникальный идентификатор для каждого элемента, например, `id`
                const newRows = action.payload.rows.filter(row => !existingIds.has(row.id));
                state.allUsers.rows = state.allUsers.rows?.length ? [...state.allUsers.rows, ...newRows] : action.payload.rows;
            }
        });
        builder.addCase(getAllUsers.rejected, (state) => {
            state.loading = false;
        });
        builder.addCase(getWeightProgress.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(getWeightProgress.fulfilled, (state, action) => {
            state.loading = false;
            state.weightProgress = action.payload
        });
        builder.addCase(getWeightProgress.rejected, (state) => {
            state.loading = false;
        });
        builder.addCase(getWorkingWeight.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(getWorkingWeight.fulfilled, (state, action) => {
            state.loading = false;
            state.workingWeights = action.payload
        });
        builder.addCase(getWorkingWeight.rejected, (state) => {
            state.loading = false;
        });
        builder.addCase(getAllExercisesForUser.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(getAllExercisesForUser.fulfilled, (state, action) => {
            state.loading = false;
            if (state.exercises.search) {
                state.exercises.rows = action.payload.rows
            } else {
                state.exercises.rows = state.exercises.rows?.length ? [...state.exercises.rows, ...action.payload.rows] : action.payload.rows
            }
            state.exercises.count = action.payload.count
        });
        builder.addCase(getAllExercisesForUser.rejected, (state) => {
            state.loading = false;
        });
    },
});

export const {
    clearUserById, setUsers, setUsersPage, clearUsersRows, setExercisePage, clearExerciseRows, setExerciseSearch
} = authSlice.actions
export default authSlice.reducer;
