import React from "react";
import { StyledWrapper } from "./styled";
import { IoIosSearch } from "react-icons/io";

const SearchInput = ({ onchange, value }) => {
  return (
    <StyledWrapper>
      <IoIosSearch className="icon" />
      <input
        type="text"
        placeholder="Поиск"
        value={value}
        onChange={onchange}
      />
    </StyledWrapper>
  );
};

export default SearchInput;
