import { Formik } from "formik";
import { RegistrationWrapper } from "./styled";
import Input from "../../components/input";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { onLogin } from "../../store/features/auth/slice";

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const initialState = {
    login: "",
    password: "",
  };

  const handleSubmitForm = async (values) => {
    const data = {
      ...values,
    };
    dispatch(onLogin(data)).then((res) => {
      if (res && res.payload) {
        navigate("/");
      }
    });
  };

  return (
    <RegistrationWrapper>
      <div className="title">Личные данные</div>

      <Formik
        initialValues={initialState}
        enableReinitialize
        onSubmit={handleSubmitForm}
      >
        {(formik) => {
          const { values, handleChange, handleSubmit } = formik;

          return (
            <form onSubmit={handleSubmit} className="registrForm">
              <Input
                type="text"
                value={values.login}
                label="Логин"
                onchange={handleChange}
                name="login"
              />
              <Input
                type="password"
                value={values.password}
                label="Пароль"
                onchange={handleChange}
                name="password"
              />
              <button type="submit">Войти</button>
            </form>
          );
        }}
      </Formik>
    </RegistrationWrapper>
  );
};
export default Login;
