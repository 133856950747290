import { StyledProfileWrapper } from "./styled";
import { useNavigate, useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import PageTitle from "../../../components/pageTitle";
import { clearUserById, getGroups, getOneUser } from "../../../store/features/auth/slice";
import { genders } from "../../../constants";
import { selectAllGroups, selectUserById } from "../../../store/features/auth/selectors";

const Profile = () => {
  const params = useParams()
  const userById = useSelector(selectUserById());
  const groups = useSelector(selectAllGroups())?.rows;
  const dispatch = useDispatch();

  useEffect(() => {
    if (params?.id) {
      dispatch(getOneUser(params?.id));
    }
    return () => {
      dispatch(clearUserById())
    }
  }, [params, dispatch]);
  useEffect(() => {
    if (userById) {
      dispatch(getGroups(userById.gender));
    }
  }, [dispatch, userById]);

  const navigate = useNavigate();
  return (
    <StyledProfileWrapper>
      <PageTitle title="Личные данные" backFunc={() => navigate(-1)} />

      {userById && (
        <div className="userInfo">
          <div className="col">
            <p>Имя:</p>
            <p>{userById.first_name}</p>
          </div>
          <div className="col">
            <p>Фамилия:</p>
            <p>{userById.last_name}</p>
          </div>
          <div className="col">
            <p>Возраст:</p>
            <p>{userById.age}</p>
          </div>
          <div className="col">
            <p>Вес:</p>
            <p>{userById.weight}</p>
          </div>
          <div className="col">
            <p>Рост:</p>
            <p>{userById.height}</p>
          </div>
          <div className="col">
            <p>% жира:</p>
            <p>{userById.fat_percent || '--'}</p>
          </div>
          <div className="col">
            <p>Количество тренировок в неделю:</p>
            <p>{userById.number_workouts_per_week}</p>
          </div>
          <div className="col">
            <p>Среднее количество шагов в неделю:</p>
            <p>{userById.average_number_steps_per_week}</p>
          </div>
          <div className="col">
            <p>Цель занятий:</p>
            <p>{groups?.find((i) => i.id === userById.group_id)?.name}</p>
          </div>
          <div className="col">
            <p>Пол:</p>
            <p>{genders?.find((i) => i.id === userById.gender)?.name}</p>
          </div>
        </div>
      )}
    </StyledProfileWrapper>
  );
};

export default Profile;
