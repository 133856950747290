import styled from "styled-components";

export const StyledTriningData = styled.div`
    max-width: 340px;
    padding-top: 45px;
    margin: 0 auto;
    min-height: 100vh;
    .subtitle{
        color: var(--text1-color);
        font-size: 15px;
        font-weight: 700;
        margin-bottom: 8px;
    }
    .item{
        cursor: pointer;
        font-size: 15px;
        border: 2px solid var(--text1-color);
        border-radius: 8px;
        margin-bottom: 18px;
        font-weight: 500;
        color: var(--text1-color);
        display: flex;
        align-items: center;
        padding-left: 12px;
        height: 48px;
    }
`
