import styled from "styled-components";

export const ModalContent = styled.div`
  position: relative;
  .title{
    width: 100%;
    font-size: 15px;
    color: var(--text1-color);
    text-align: center;
  }
  .flex{
    margin-top: 30px;
    display: flex;
    justify-content: center;
    gap: 16px;
    button{
      background: transparent;
      border: 1px solid var(--text1-color);
      color: var(--text1-color);
      border-radius: 24px;
      cursor: pointer;
      padding: 5px 10px;
      font-size: 13px;
      line-height: 13px;
      height: 24px;
    }
  }
`;
