import styled from "styled-components";

export const NutritionWrapper = styled.div`
    max-width: 340px;
    padding-top: 45px;
    margin: 0 auto;
    .item{
        cursor: pointer;
        text-decoration: none;
        font-size: 15px;
        border: 2px solid var(--text1-color);
        border-radius: 8px;
        margin-bottom: 18px;
        font-weight: 500;
        color: var(--text1-color);
        display: flex;
        align-items: center;
        padding-left: 12px;
        height: 48px;
    }
    .loadMoreBtn {
        display: block;
        width: max-content;
        margin: 20px auto 0 auto;
        background-color: lightgreen;
        padding: 8px;
        border-radius: 5px;
        border: none;
        cursor: pointer;
    }
`;


export const StyledTable = styled.div`
        width: 100%;
        text-align: center;
        margin-top: 40px;
        .title{
            font-size: 20px;
            font-weight: 700;
            color: var(--text1-color)        
        }
        .content{
            margin-top: 30px;
            border: 2px solid var(--text1-color);
            border-radius: 15px;
            color: var(--text1-color);
            width: 100%;
            display: flex;
            div {
                flex: 1;
                flex-direction: column;
                p {
                    display: flex;
                    border: 2px solid var(--text1-color);
                    border-top: none;
                    border-left: none;
                    justify-content: center;
                    align-items: center;
                    height: 60px;
                    size: 16px;
                    font-weight: 300;
                    text-align: center;
                }

                p:last-child{
                    border-bottom: none
                }

                &:last-child{
                    p{
                        border-right: none;
                    }
                }
            }
        }
`
