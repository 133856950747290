import { DashboardWrapper } from "./styled";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import PageTitle from "../../../components/pageTitle";
import { useDispatch, useSelector } from "react-redux";
import { clearCustomData, deleteCustom, getAllNews } from "../../../store/features/reviews/slice";
import { selectCustomData } from "../../../store/features/reviews/selectors";
import DropDown from "../../../components/dropDown";
import DeleteCustomModal from "../../../components/modals/deleteCustomModal";

const titleTranslate = {
  Updates: "Обновления",
  InterestingFacts: "Интересные факты",
  ScientificResearch: "Научные исследования",
  ApplicationOperation: "Работа приложения",
  DiscountsAndPromotions: "Скидки и акции",
  QuestionAnswer: "Помощь",
}
export const toUnderlineModel = {
  Updates: "updates",
  InterestingFacts: "interesting_facts",
  ScientificResearch: "scientific_research",
  ApplicationOperation: "application_operation",
  DiscountsAndPromotions: "discounts_and_promotions",
  QuestionAnswer: "question_answer",
}

const CustomSinglePage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { type } = useParams();
  const data = useSelector(selectCustomData())
  const [selected, setSelected] = useState()
  const [openModal, setOpenModal] = useState(false)

  useEffect(() => {
    dispatch(getAllNews(type))
    return () => {
      dispatch(clearCustomData())
    }
  }, [dispatch, type])

  const handleSelect = (id) => {
    if (selected === id) {
      setSelected(null)
    } else {
      setSelected(id)
    }
  }

  const handelDelete = (id) => {
    setOpenModal(true)
  }

  const deleteReq = () => {
    const data = {
      id: selected,
      model: type
    }
    dispatch(deleteCustom(data)).then(res => {
      if (res.payload) {
        setOpenModal(false)
      }
    })
  }


  return (
    <DashboardWrapper>
      <PageTitle title={titleTranslate[type]} backFunc={() => navigate(-1)} />
      {data?.rows?.map((item) => {
        const key = toUnderlineModel[type]
        const files = item[key]
        return (
          <DropDown
            key={item.id}
            files={files}
            edit={item.id}
            remove={() => handelDelete(item.id)}
            open={selected === item.id}
            title={item.title}
            text={item.description}
            onClick={() => handleSelect(item.id)}
          />
        )
      })}
      <br />
      <br />
      <DeleteCustomModal open={openModal} handleModal={() => setOpenModal(false)} handleOk={deleteReq} />
    </DashboardWrapper>
  );
};
export default CustomSinglePage;
