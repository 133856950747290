import styled from "styled-components";

export const NewsWrapper = styled.div`
    max-width: 340px;
    padding-top: 45px;
    margin: 0 auto;
    textarea{
        background: transparent;
        width: 100%;
        resize: none;
        outline: none;
        border: 2px solid var(--text1-color);
        color: var(--text1-color);
        border-radius: 10px;
        margin: 8px 0;
        padding: 8px 12px ;
        &::-webkit-scrollbar {
        display: none;
        }
        &::placeholder {
            color: var(--text1-color);
        }
    }
    button{
        width: max-content;
        cursor: pointer;
        padding: 10px 20px;
        border: 1px solid var(--text1-color);
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: var(--text1-color);
        color: var(--background);
        border-radius: 24px;
        margin: 10px auto 0 auto;
        font-size: 20px;
        font-weight: 700;
        &:disabled{
            background: gray;
            border-color: gray;
            cursor: default;
        }
    }
    .grid{
        margin-top: 40px;
        display: grid;
        gap: 8px;
        grid-template-columns: 1fr 1fr;
        div{
            width: 160px;
            height: 160px;
            background-color: #d9d9d9;
        }
        .preview{
            position: relative;
            p{
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%,-50%);
                text-align: center;
                font-size: 13px;
                font-weight: 300;
            }
            img{
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }
`;


