import React from "react";
import { StyledCalendarWrapper } from "./styled";
import Calendar from "react-calendar";

const CalendarComp = ({ onchange, value, tileContent, onChangeMonth }) => {
  const tileDisabled = ({ date }) => {
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth();
    const currentYear = currentDate.getFullYear();

    const dateMonth = date.getMonth();
    const dateYear = date.getFullYear();

    if (dateYear === currentYear && (dateMonth === currentMonth || dateMonth === currentMonth + 1)) {
      return false;
    }

    if (dateYear === currentYear + 1 && currentMonth === 11 && dateMonth === 0) {
      return false;
    }

    return true;
  };

  const tileClassName = ({ date }) => {
    return tileDisabled({ date }) ? 'disabled' : 'item';
  };

  return (
    <>
      <StyledCalendarWrapper>
        <Calendar
          onClickMonth={onChangeMonth}
          onChange={onchange}
          tileContent={tileContent}
          tileDisabled={tileDisabled}
          tileClassName={tileClassName}
          value={value}
          className="calendar"
          nextLabel={false}
          next2Label=""
          prev2Label=""
          prevLabel=""
          navigationLabel={({ label }) => label.split(" ")[0]}
          minDetail="year"
          showNeighboringMonth={true}
          locale="ru"
        />
      </StyledCalendarWrapper>
    </>
  );
};

export default CalendarComp;
