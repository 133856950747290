import React from "react";
import { SubModalContent } from "./styled";
import Modal from "react-modal";
import { Link } from "react-router-dom";
import { IoIosClose } from "react-icons/io";

const SubscriptionModal = ({ open, handleModal }) => {
  Modal.setAppElement("#root");
  const app = document.querySelector(".App");
  const isLightTheme = app?.classList?.value.includes("light");

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      width: "340px",
      height: "234px",
      background: "var(--background)",
      borderRadius: "21px",
      border: "2px solid var(--text1-color)",
    },
    overlay: {
      background: "transparent",
    },
  };
  return (
    <Modal
      className={isLightTheme ? "light lightModal" : ""}
      isOpen={open}
      onRequestClose={() => handleModal(false)}
      style={customStyles}
    >
      {open && (
        <IoIosClose
          className="modalCloseIcon"
          onClick={() => handleModal(false)}
        />
      )}
      <SubModalContent>
        <p className="text">
          Чтобы использовать все возможности приложения - оформите подриску
        </p>
        <Link to={"#"} className="button">
          Оформить <br /> подписку
        </Link>
      </SubModalContent>
    </Modal>
  );
};

export default SubscriptionModal;
