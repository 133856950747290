import { useState } from "react";
import "./App.css";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import TriningData from "./pages/trainingData";
import Nutrition from "./pages/nutrition";
import NotFound from "./pages/404";
import Dashboard from "./pages/dashboard";
import Technique from "./pages/technique";
import ExercisesList from "./pages/exercisesList";
import Login from "./pages/login";
import TriningFullInfo from "./pages/trainingData/fullinfo";
import Profile from "./pages/profile";
import ProfileUpdate from "./pages/profile/update";
import CreateExercise from "./pages/exercisesList/createExercise";
import ExerciseFullInfo from "./pages/exercisesList/fullInfo";
import FullInfo from "./pages/nutrition/fullInfo";
import Users from "./pages/users";
import UserProgress from "./pages/progress";
import Review from "./pages/review";
import NewReview from "./pages/review/newReview";
import News from "./pages/news";
import AddNews from "./pages/news/addNews";
import CustomSinglePage from "./pages/news/singlePage";
import AddCart from "./pages/addCart";
import UserFullInfo from "./pages/users/fullInfo";

function App() {
  const [isLight, setIsLight] = useState(false);
  const navigate = useNavigate();
  const location = useLocation()

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
      if (!localStorage.getItem('token') && location.pathname !== '/login') {
          navigate('/login');
      }
  }, [location.pathname, navigate])

  return (
    <div className={`App ${isLight ? "light" : ""}`}>
      <button
        style={{ position: "fixed", right: 0, top: 0 }}
        onClick={() => {
          setIsLight(!isLight);
        }}
      >
        o
      </button>

      <Routes>
        <Route path="/" element={<Dashboard />} />
        <Route path="/trinings" element={<TriningData />} />
        <Route path="/trining/:id" element={<TriningFullInfo />} />
        <Route path="/nutrition" element={<Nutrition />} />
        <Route path="/nutrition/:id" element={<FullInfo />} />
        <Route path="/technique" element={<Technique />} />
        <Route path="/exercisesList/:id" element={<ExercisesList />} />
        <Route path="/createExercise" element={<CreateExercise />} />
        <Route path="/createExercise/:id" element={<CreateExercise />} />
        <Route path="/exerciseFullInfo/:id" element={<ExerciseFullInfo />} />
        <Route path="/login" element={<Login />} />
        <Route path="/profile/:id" element={<Profile />} />
        <Route path="/profile/:id/:day/update" element={<ProfileUpdate />} />
        <Route path="/users" element={<Users />} />
        <Route path="/user/:id" element={<UserFullInfo />} />
        <Route path="/progress/:id" element={<UserProgress />} />
        <Route path="/review" element={<Review />} />
        <Route path="/newReviews" element={<NewReview />} />
        <Route path="/custom/:type" element={<CustomSinglePage />} />
        <Route path="/custom/:type/:id" element={<AddNews />} />
        <Route path="/custom" element={<News />} />
        <Route path="/addNews" element={<AddNews />} />
        <Route path="/addCart" element={<AddCart />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </div>
  );
}

export default App;
