import styled from "styled-components";

export const StyledPageTitle = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  color: var(--text1-color);
  .back {
    flex: 1;
    cursor: pointer;
    font-weight: 300;
    display: flex;
    align-items: center;
    line-height: 10px;
    font-size: 14px;
    svg {
      font-size: 16px;
    }
  }
  .title {
    flex: 1;
    text-align: center;
    font-weight: 700;
    font-size: 17px;
  }
  .edit{
    position: relative;
    text-align: end;
    cursor: pointer;
    font-size: 13px;
    text-decoration: underline;
    flex: 1;
  }
`;
