import axios from 'axios';

const BASE_URL = process.env.REACT_APP_BASE_URL;

export const client = axios.create({
    baseURL: BASE_URL,
    headers: {
        'Content-Type': 'application/json',
        'token': localStorage.getItem('token')
    }
});
export const clientForm = axios.create({
    baseURL: BASE_URL,
    headers: {
        'Content-Type': 'multipart/form-data',
        'token': localStorage.getItem('token')
    }
});