import React from "react";
import { StyledDropDown } from "./styled";
import { FaAngleDown } from "react-icons/fa";
import { Link, useParams } from "react-router-dom";
import SliderComp from "../slider";

const DropDown = ({ open, title, text, onClick, remove, edit, files }) => {
  const { type } = useParams();
  return (
    <StyledDropDown open={open} onClick={onClick}>
      <div className="title">
        {title}
        <div className="arrow">
          <FaAngleDown />
        </div>
      </div>
      <div className="drop" onClick={(e) => e.stopPropagation()}>
        <pre>{text}</pre>
        {files?.length ?
          <>
            <br />
            <br />
            <SliderComp objKey={'url'} files={files} />
          </>
          : ''}
        <br />

        <div className="actions">
          <Link to={`/custom/${type}/${edit}`} className="edit">Редактировать</Link>
          <div className="remove" onClick={remove}> Удалить</div>
        </div>
      </div>
    </StyledDropDown>
  );
};

export default DropDown;
