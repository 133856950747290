
import React from "react";
import { BanksModalContent } from "./styled";
import Modal from "react-modal";
import { IoIosClose } from "react-icons/io";
import { useDispatch } from "react-redux";
import { deleteCart, getCarts } from "../../../store/features/reviews/slice";

const DeleteCartModal = ({ open, handleModal, selected }) => {
  Modal.setAppElement("#root");
  const app = document.querySelector(".App");
  const isLightTheme = app?.classList?.value.includes("light");
  const dispatch = useDispatch()

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      width: "340px",
      background: "var(--background)",
      borderRadius: "21px",
      border: "2px solid var(--text1-color)",
    },
    overlay: {
      background: "transparent",
    },
  };

  const handleDelete = async () => {
    const res = await dispatch(deleteCart(selected.id))
    if (res.payload) {
      handleModal(null)
      dispatch(getCarts())
    }
  }
  return (
    <Modal
      className={isLightTheme ? "light lightModal" : ""}
      isOpen={open}
      onRequestClose={() => handleModal(false)}
      style={customStyles}
    >
      {open && (
        <IoIosClose
          className="modalCloseIcon"
          onClick={() => handleModal(false)}
        />
      )}
      <BanksModalContent>
        <p className="title">Удалить карту ?</p>
        <div>
          <button onClick={handleDelete}>Да</button>
          <button onClick={() => handleModal(null)}>Нет</button>
        </div>
      </BanksModalContent>
    </Modal>
  );
};

export default DeleteCartModal;
