import React, { useEffect } from "react";
import PageTitle from "../../components/pageTitle";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { getActiveReviews } from "../../store/features/reviews/slice";
import { selectActiveReviews } from "../../store/features/reviews/selectors";
import { StyledReview, StyledReviewItem } from "./styled";
import star from '../../images/icons/star.svg'

const Review = () => {
  const navigate = useNavigate();
  const actives = useSelector(selectActiveReviews());
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getActiveReviews());
  }, [dispatch]);

  return (
    <StyledReview>
      <PageTitle
        title="Отзывы"
        backFunc={() => navigate(-1)}
        editText={`Новые отзывы`}
        editFunc={() => navigate('/newReviews')}
      />
      <br />
      {actives?.rows?.map(item => {
        return (
          <StyledReviewItem key={item.id}>
            <div className="title">
              <p>
                {item?.user?.first_name + ' ' + item?.user?.last_name}
              </p>
              <p className="stars">{item.stars} <img src={star} alt="star" /></p>
            </div>
            <p className="text">{item?.description}</p>
          </StyledReviewItem>
        )
      })}

    </StyledReview>
  );
};

export default Review;
